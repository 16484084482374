import "./invoice.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Space, Input, InputNumber, Spin } from "antd";
import { useState, useEffect, useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import { BiEnvelope } from "react-icons/bi";
import { BiRewindCircle } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { BiUpload } from "react-icons/bi";
import { IconContext } from "react-icons";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DescriptionIcon from "@mui/icons-material/Description";
import { faFileLines, faPaste } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BiMerge } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import {
  faXmark,
  faCirclePlus,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import InsertPageBreakOutlinedIcon from "@mui/icons-material/InsertPageBreakOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DataTable from "./Table";
import DataTable1 from "./Table1";
import DataTable2 from "./Table2";
import FilterSection from "./filter";
import Header from "../Header/header";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import MappingTopBarReview from "./MappingTopBarReview";
import MappingTopBar from "./MappingTopBar";
import Bbutton from "@mui/material/Button";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import "../App.css";
import "./filter.css";
import * as moment from "moment";
const { Search } = Input;
const Invoice = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [resid, setResid] = useState([]);
  const [isScrolledTooltip, setisScrolledTooltip] = useState(false);
  const [isTableScrolled, setIsTableScrolled] = useState(false);
  const getToken = () => {
    const token1 = localStorage.getItem("auth-token");
    const normalheaders = {
      Authorization: "Bearer " + token1,
      "Content-Type": "application/json",
    };
    return normalheaders;
  };
  const [isChecked, setIsChecked] = useState(false);
  const [file_status, setFile_status] = useState();
  const [deletepayload, setdeletepayload] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [deletecatid, setdetetecatid] = useState();
  const [deteteinvoiceid, setdeteteinvoiceid] = useState("");
  const [exist_Processor, setexist_Processor] = useState(false);
  const [showMergeAlert, setShowMergeAlert] = useState(false);
  let navigate = useNavigate();
  // VARIABLE DECLARATION FOR DUPLICATE INVOICE POPUP
  const [IndicationOfDuplicateInvoice, setIndicationOfDuplicateInvoice] =
    useState(false);
  const [
    IndicationOfSyscoDuplicateInvoice,
    setIndicationOfSyscoDuplicateInvoice,
  ] = useState(false);
  const [DuplicateInvImgIndex, setDuplicateInvImgIndex] = useState(0);
  const [existImageFile, setexistImageFile] = useState();
  const [existSourceFile, setexistSourceFile] = useState();
  const [duplicateImageFile, setduplicateImageFile] = useState();
  const [pageCountOne, setpageCountOne] = useState(1);
  const [pageCountTwo, setpageCountTwo] = useState(2);
  const [RestrictDuplicate, setRestrictDuplicate] = useState(false);
  const [loaderForMergePdf, setLoaderForMergePdf] = useState(false);
  //DATE VARIABLE DECLARATION
  const [ExistText, setExistText] = useState("");
  const [existCheck, setexistCheck] = useState("");
  const [updatedate, setUpdatedate] = useState(() => {
    if (
      localStorage.getItem("Active_page") == "1" ||
      localStorage.getItem("Active_page") == "" ||
      localStorage.getItem("Active_page") == null
    ) {
      return new Date(localStorage.getItem("YetToStart_date"))
        .toISOString()
        .split("T", 2)[0];
    } else {
      return new Date(localStorage.getItem("yettostart_startdate_fobegolf"))
        .toISOString()
        .split("T", 2)[0];
    }
  });

  const [currentDate, setcurrentDate] = useState(() => {
    return new Date().toISOString().split("T")[0];
  }); // get current date in ISO format

  function handleDateChange(event) {
    if (event.target.name === "updatedate") {
      setUpdatedate(event.target.value);
    } else if (event.target.name === "currentDate") {
      setcurrentDate(event.target.value);
    }
  }

  const [recordStateforMapping, setrecordStateforMapping] = useState([]);
  const [ReviewData, setReviewData] = useState([]);
  const [dataForPreandNext, setdataForPreandNext] = useState([]);

  ///////////////////////////

  const RescanFunction = (record) => {
    record = { ...record, rescan: true };
    getInvoiceDataUrl(record);
  };

  //DROPDOWN VARIABLE DECLARATION
  const [dropdown1Value, setDropdown1Value] = useState("");
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [dropdown3Value, setDropdown3Value] = useState("1");
  const [dropdown4Value, setDropdown4Value] = useState("");
  const [dropdown5Value, setDropdown5Value] = useState(
    localStorage.getItem("Active_page") ||
      localStorage.getItem("Active_page") == ""
      ? localStorage.getItem("Active_page")
      : "1"
  );
  const [fullgetData, setfullgetData] = useState([]);
  const [DropdownChangeForDeleteReason, setDropdownChangeForDeleteReason] =
    useState("");
  const AcceptReject = async (e) => {
    if (e == 1) {
      setQa_review("1");
    } else {
      setQa_review("2");
    }
    var data = {
      Rest_id: ReviewFullData.Rest_id,
      key: e,
      upload_id: ReviewFullData.Upload_id,
    };
    await fobeAxios
      .post("/api/Daily/QA_check", data, { headers: getToken() })
      .then((res) => {
        if (res.data.status == 1) {
          toast.success("Invoice Review Status Updated Successfully");
          if (e == 2) {
            setNotesPopup(true);
            getNotes(ReviewFullData);
          }
        } else {
          toast.error("Failed to Update");
        }
        const datamap = tableData.map((item) => {
          if (item.Upload_id == ReviewFullData.Upload_id) {
            item.qa_review = e;
          }
          return item;
        });
        setTableData(datamap);
        // setdataForPreandNext(datamap);
        setFilteredData(datamap);
        setfullgetData(datamap);
        setFilteredData1(datamap);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleDropdownChangeForDeleteReason = (event) => {
    setDropdownChangeForDeleteReason(event.target.value);
  };

  //POPUP VARIABLE DECLARATION
  const [showdeletealert, setshowdeletealert] = useState(false);
  const handleCloseshowdeletealert = () => {
    setshowdeletealert(false);
    setDropdownChangeForDeleteReason("");
  };
  const handleShowshowdeletealert = () => setshowdeletealert(true);

  const [showrevertdata, setshowrevertdata] = useState(false);
  const handleCloseforshowrevertdata = () => setshowrevertdata(false);
  const handleShowforshowrevertdata = () => setshowrevertdata(true);

  const [showConformPopforRevertdata, setshowConformPopforRevertdata] =
    useState(false);
  const handleCloseshowConformPopforRevertdata = () =>
    setshowConformPopforRevertdata(false);
  const handleShowshowConformPopforRevertdata = () =>
    setshowConformPopforRevertdata(true);

  const [show, setShow] = useState(false);
  const [notesPopup, setNotesPopup] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSelectedOption(Array(tableData.length).fill(null));
  };
  const handleShow = () => setShow(true);
  const handleCheckboxChange = (e, record) => {
    console.log(record.Upload_id, "record.upload_id");
    if (e.target.checked) {
      setSelectedRows([...selectedRows, record.Upload_id]);
      setResid([...resid, record.Rest_id]);
    } else {
      setSelectedRows(
        selectedRows.filter((item) => {
          return item != record.Upload_id;
        })
      );
      setResid(
        resid.filter((item) => {
          return item != record.Rest_id;
        })
      );
    }
    console.log(selectedRows);
  };

  //TABLE VARIABLE DECLARATION

  const [AiMapTableData, setAiMapTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    Array(tableData.length).fill(null)
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [apikeyfordelete, setapikeyfordelete] = useState();
  const [apikey, setapikey] = useState({ label: "", value: "", upload_id: "" });
  const [showpdf, setshowpdf] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [AiMapTableDataFilter, setAiMapTableDataFilter] = useState({});
  const [sorter, setSorter] = useState({});
  const [AiMapTableDataSoter, setAiMapTableDataSoter] = useState({});
  const [searchText, setSearchText] = useState("");
  const [AimapsearchText, setAiMearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [AimapFilteredData, setAimapFilteredData] = useState([]);
  const [restlistfordrop, setrestlistfordrop] = useState();
  const [total, setTotal] = useState();
  const [color_key, setcolor_key] = useState("");

  const [mergeshow, setmergeShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [images, setImages] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [mappingInfo, setmappingInfo] = useState(false);
  const [statistics, setStatistics] = useState([])
  const [changedLineItemList, setChangedLineItemList] = useState({});
  const multicheckk = (checked) => {
    console.log(checked, "event");
    setIsChecked(checked);
    // Get all indices of yourData
    if (checked) {
      setMultiDeleteItems(AiMapTableData.map((item, index) => index));
      setShowDeleteIcon(true);
    } else {
      setShowDeleteIcon(false);
      setMultiDeleteItems([]);
    }
    console.log(MultiDeleteItems, "MultiDeleteItems");
  };

  const removeImage = (index) => {
    setImages((currentImages) => {
      const newImages = [...currentImages];
      newImages.splice(index, 1);
      return newImages;
    });

    setSelectedRows((updatedRows) => {
      const newImages = [...updatedRows];
      newImages.splice(index, 1);
      return newImages;
    });
    // Update selectedImageIndex accordingly
    if (index === selectedImageIndex) {
      if (index > 0) {
        // Select the previous image
        setSelectedImageIndex(index - 1);
      } else if (images?.length > 1) {
        // Select the next image, which is now at the current index
        setSelectedImageIndex(index);
      } else {
        // No images left
        setSelectedImageIndex(null);
      }
    } else if (index < selectedImageIndex) {
      // Adjust the index if a preceding image is removed
      setSelectedImageIndex((currentIndex) => currentIndex - 1);
    }
  };

  const handleDragStart = (index) => (e) => {
    e.dataTransfer.setData("draggedImageIndex", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (droppedOnIndex) => (e) => {
    e.preventDefault();
    const draggedIndex = parseInt(
      e.dataTransfer.getData("draggedImageIndex"),
      10
    );

    if (draggedIndex === droppedOnIndex) {
      return; // Dropped on the same image
    }

    setImages((currentImages) => {
      const newImages = [...currentImages];
      const temp = newImages[draggedIndex];
      newImages[draggedIndex] = newImages[droppedOnIndex];
      newImages[droppedOnIndex] = temp;
      return newImages;
    });
  };

  const allImagesClosed = images?.every((image) => image?.closed);
  // const images = new Array(5).fill('');

  const handlemergeShow = (val) => {
    setLoading(true);
    const filteredRows = resid?.filter((row) => row !== resid[0]);
    if (filteredRows.length > 0) {
      setResid([]);
      setSelectedRows([]);
      setShowMergeAlert(true);
      setLoading(false);
      setLoaderForMergePdf(false); // Set loader to false to stop loading
    } else {
      setLoaderForMergePdf(true);

      let request = {
        upload_id: val,
      };

      fobeAxios
        .post("api/Daily/getFirstpageOfPDF", request)
        .then((res) => {
          if (res.data) {
            setmergeShow(true);
            setLoaderForMergePdf(false);
            setLoading(false);
            const temp = [];
            val.forEach((out) => {
              temp.push(res.data[out]);
            });
            setImages(temp);
            setSelectedImageIndex(temp?.length > 0 ? 0 : null);
          } else {
            toast.warning("Faild");
            setLoaderForMergePdf(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleCloseMergeAlert = () => {
    setShowMergeAlert(false);
  };

  const handlemergeClose = () => {
    setmergeShow(false);
    setSelectedRows([]);
    setResid([]);
  };

  const mergePfd = () => {
    if (images.length <= 1) {
      toast.warning("Please select more images for merge");
      return;
    }
    setLoaderForMergePdf(true);
    let request = {
      upload_id: selectedRows,
    };
    fobeAxios
      .post("api/User/mergePDF", request)
      .then((res) => {
        if (res) {
          if (res.data.status == 0) {
            toast.error("PDF Merge Failed");
            setLoaderForMergePdf(false);
            // setmergeShow(false)
            // setSelectedRows([])
            // setResid([])
          } else {
            setLoaderForMergePdf(false);
            setmergeShow(false);
            setSelectedRows([]);
            setResid([]);
            getData(updatedate);
            toast.success("PDF Merged Successfully");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoaderForMergePdf(false);
      });
  };
  const mapcatname = async (e) => {
    let req = {
      Rest_id: apikey.value,
      upload_id: apikey.upload_id,
      companyname: apikey.label,
    };
    await fobeAxios.post("Api/Daily/mail_invoice_update", req).then((resp) => {
      handleClose();
      if (resp.data.status == 1) {
        setcolor_key(apikey.upload_id);
        toast.success("Restaurant Added Successfully");
        getData(updatedate);
      } else {
        toast.error("Restaurant is Not Added");
      }
    });
  };

  useEffect(() => {
    if (sorter.field === "invUploadDate") {
      if (sorter.order === "ascend") {
        const sortedArray = [...tableData].sort((a, b) =>
          a.date.localeCompare(b.date)
        );
        setTableData(sortedArray);
      }
      if (sorter.order === "descend") {
        const sortedArray = [...tableData].sort((a, b) =>
          b.date.localeCompare(a.date)
        );
        setTableData(sortedArray);
      }
    }
  }, [sorter]);

  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const handleTableChange = (pagination, filters, sorter) => {
    // setPagination(pagination);
    setCurrentIndex(pagination.current - 1);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    //  setSorter(sorter);
  };
  const AiMapTableHandleChange = (pagination, filters, sorter) => {
    // setPagination(pagination);
    setAiMapTableDataFilter(filters);
    setAiMapTableDataSoter(sorter);
  };

  const AiMappinghandleSearch = (e) => {
    setLoadingaimap(true);
    const value = e.target.value;
    if (e.target.value != "" && e.target.value != null) {
      const filtered = AimapFilteredData.filter((record) => {
        const values = Object.values(record).join("").toLowerCase();
        return values.includes(e.target.value.toLowerCase());
      });
      setAiMapTableData([]);
      setTimeout(() => {
        setAiMapTableData(filtered);
        setLoadingaimap(false);
      }, 100);
    } else {
      setAiMapTableData([]);
      setTimeout(() => {
        setAiMapTableData(AiMapTableData);
        setLoadingaimap(false);
      }, 100);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  function viewinvoicee(record) {
    window.open(record);
  }
  // POPUP
  const [indexdelete, setindexdelete] = useState("");
  const pop = (key, resid, index) => {
    if (key == "revertsplit") {
      setshowConformPopforRevertdata(true);
      // setshowrevertdata(false);
    }
    if (key == "delete") {
      setapikeyfordelete(resid);
      setindexdelete(index);

      handleShowshowdeletealert();
    }
  };
  //DELETE API
  const Delete = async () => {
    if (DropdownChangeForDeleteReason != "") {
      handleCloseshowdeletealert(false);
      await fobeAxios
        .get(
          "/api/Daily/Delete_invoice_details/?uploadid=" +
          apikeyfordelete +
          "&deletedRowReason=" +
          DropdownChangeForDeleteReason
        )
        .then(async (res) => {
          if (res.data.status == 1) {
            setDropdownChangeForDeleteReason("");
            toast.success("Invoice Details Deleted Successfully.");
            await getData(updatedate);
            if (AImapActiveClass) {
              nextAndPrevious(1, indexdelete);
            }
          } else {
            setDropdownChangeForDeleteReason("");
            toast.error("Invoice Details Deleted Failed.");
            await getData(updatedate);
            if (AImapActiveClass) {
              nextAndPrevious(1, indexdelete);
            }
          }
        });
    } else {
      toast.warning("Please Select the Reason");
    }
  };
  //REVERT SPLIT API
  const Revertsplit = (e) => {
    var data = {
      rest_id: showpdf.rest_id,
      upload_id: showpdf.source_upload_id,
    };
    fobeAxios
      .post("api/Admin/saveoperation_forretrieve_old", data)
      .then((res) => { });
    toast.success("original file revert successfully.");
    setshowConformPopforRevertdata(false);
    getData(updatedate);
  };
  const [loading, setLoading] = useState(false);
  const [CommonArray, setCommonArray] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  //GET DATA API
  const getdata1 = () => {
    getData(updatedate);
  };
  const getData = (start) => {
    // setSearchText("");
    try {
      setLoading(true);
      setUpdatedate(start);
      let accid = dropdown5Value == "" ? 0 : dropdown5Value;
      let file_status  = dropdown3Value == "" ? 0 : dropdown3Value
      fobeAxios
        .get(
          "api/daily/Getinvoice_details/" +
          start +
          "," +
          currentDate +
          "/" +
          accid +
          "/"+
          file_status
        )
        .then(async (res) => {
          const data = res?.data?.Invoice_Details?.map((item) => ({
            Select: false,
            Upload_id: item.rest_details.Upload_id,
            Rest_id: item.rest_details.Rest_id,
            ownername: item.rest_details.ownername,
            company: item.rest_details.company,
            filename: item.rest_details.filename,
            date: item.rest_details.date,
            Completed_date: item.rest_details.Completed_date,
            rawdata: item.rest_details.rawdata,
            invoice_from:
              item.rest_details.invoice_from == null
                ? "INVOICE_UPLOAD"
                : item.rest_details.invoice_from == ""
                  ? "INVOICE_UPLOAD"
                  : item.rest_details.invoice_from == "SPLIT_PDF"
                    ? "SPLIT_PDF"
                    : item.rest_details.invoice_from,
            split_pdf:
              item.rest_details.invoice_from == "SPLIT_PDF" ? true : false,
            file_status: item.rest_details.file_status,
            Completed_date: item.rest_details.Completed_date,
            invoicefrom_mail: item.rest_details.invoicefrom_mail,
            Budget_type: item.rest_details.Budget_type,
            Admin_Status: item.rest_details.Admin_Status,
            PDF_Page_Count: item.rest_details.PDF_Page_Count,
            Account_Id: item.rest_details.Account_Id,
            qa_review: item.rest_details.qa_review,
            Automatic_mapping:
              item.rest_details.Automatic_mapping == 0 ||
                item.rest_details.Automatic_mapping == ""
                ? 0
                : item.rest_details.Automatic_mapping,
            purveyor: item.rest_details.purveyor,
            file_reader: item.rest_details.File_reader,
            Account_Name: item.rest_details.Account_name,
            invoice_nmr: item.rest_details.invoice_nmr,
          }));
          setCommonArray(data.reverse());
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    handleDropdown5Change(dropdown5Value);
  }, [CommonArray]);
  useEffect(()=>{
    getData(updatedate)
  },[dropdown3Value])
  useEffect(() => {
    //;
    let temp;
    if (dropdown5Value === "") {
      if (
        new Date(localStorage.getItem("YetToStart_date"))
          .toISOString()
          .split("T", 2)[0] <
        new Date(localStorage.getItem("yettostart_startdate_fobegolf"))
          .toISOString()
          .split("T", 2)[0]
      ) {
        temp = new Date(localStorage.getItem("YetToStart_date"))
          .toISOString()
          .split("T", 2)[0];
      } else {
        temp = new Date(localStorage.getItem("yettostart_startdate_fobegolf"))
          .toISOString()
          .split("T", 2)[0];
      }
    }
    if (dropdown5Value === "1") {
      temp = new Date(localStorage.getItem("YetToStart_date"))
        .toISOString()
        .split("T", 2)[0];
    }
    if (dropdown5Value === "5") {
      temp = new Date(localStorage.getItem("yettostart_startdate_fobegolf"))
        .toISOString()
        .split("T", 2)[0];
    }
    getData(temp);
  }, [dropdown5Value]);

  //--------------- Filter Function --------------------//
  const Separator = (
    Inv_type,
    Budget_type,
    Status,
    Processed_by,
    Acc_type,
    Json
  ) => {
    let token = true;
    if (Inv_type != "") {
      if (Inv_type != Json.invoice_from) {
        return false;
      }
    }
    if (Budget_type != "") {
      if (Budget_type != Json.Budget_type) {
        return false;
      }
    }
    if (Status != "") {
      if (Status != Json.file_status) {
        return false;
      }
    }
    if (Processed_by != "") {
      if (Processed_by != Json.Automatic_mapping) {
        return false;
      }
    }
    if (Acc_type != "") {
      if (Acc_type != Json.Account_Id) {
        return false;
      }
    }
    return token;
  };
  useEffect(() => {
    handleSearch(searchText);
  }, [filterArray]);

  const handleSearch = (val) => {
    setSearchText(val);
    let searchresult = filterArray.filter((res) => {
      return Object.values(res).some(
        (a) =>
          (typeof a === "string" || typeof a === "number") &&
          a.toString().toLowerCase().includes(val.toLowerCase())
      );
    });
    setTableData(searchresult);
  };

  const handleDropdown1Change = (val) => {
    setDropdown1Value(val);
    let result = CommonArray.filter((res) => {
      return Separator(
        val,
        dropdown2Value,
        dropdown3Value,
        dropdown4Value,
        dropdown5Value,
        res
      );
    });
    setFilterArray(result);
  };

  const handleDropdown2Change = (val) => {
    setDropdown2Value(val);
    let result = CommonArray.filter((res) => {
      return Separator(
        dropdown1Value,
        val,
        dropdown3Value,
        dropdown4Value,
        dropdown5Value,
        res
      );
    });
    setFilterArray(result);
  };

  const handleDropdown3Change = (val) => {
    setDropdown3Value(val)
    // getData(updatedate);
    // // let result = CommonArray.filter((res) => {
    // //   return Separator(
    // //     dropdown1Value,
    // //     dropdown2Value,
    // //     val,
    // //     dropdown4Value,
    // //     dropdown5Value,
    // //     res
    // //   );
    // // });
    // // setFilterArray(result);
  };

  const handleDropdown4Change = (val) => {
    setDropdown4Value(val);
    let result = CommonArray.filter((res) => {
      return Separator(
        dropdown1Value,
        dropdown2Value,
        dropdown3Value,
        val,
        dropdown5Value,
        res
      );
    });
    setFilterArray(result);
  };

  const handleDropdown5Change = (val) => {
    localStorage.setItem("Active_page", val);
    setDropdown5Value(val);
    let result = CommonArray.filter((res) => {
      return Separator(
        dropdown1Value,
        dropdown2Value,
        dropdown3Value,
        dropdown4Value,
        val,
        res
      );
    });
    setFilterArray(result);
  };

  // view Invoice
  const [AImapActiveClass, setAImapActiveClass] = useState(false);
  const [ReviewInvActiveClass, setReviewInvActiveClass] = useState(false);
  const [AutomationReview, setAutomationReview] = useState(false);
  const [ReviewFullData, setReviewFullData] = useState([]);
  const [CantChangeUpdateReview, setCantChangeUpdateReview] = useState([]);
  // const [SendtoUpadteInvoice, setSendtoUpadteInvoice] = useState([]);
  const [loadingg, setLoadingg] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoicedate] = useState("");
  const [qa_review, setQa_review] = useState("");
  const [Purveyor, setPurveyor] = useState("");
  const getInvoiceDataUrl = async (data) => {
    setCantChangeUpdateReview([]);
    setrecordStateforMapping([]);
    setrecordStateforMapping([]);

    setInvoiceNumber("");
    setInvoicedate("");
    setAiMapTableData([]);
    setAimapFilteredData([]);
    setLoadingg("true");
    setLoadingaimapfull(true);
    setReviewData(data);
    let rescan = false;
    let verify = false;
    setReviewFullData(data.ReviewData);
    setQa_review(data.ReviewData.qa_review);
    getAimappingCategatoryList(data.ReviewData.Rest_id);
    if (data?.ReviewData?.file_status == 2) {
      let index = data.ReviewData.Rest_id;
      var data = {
        Rest_id: data.ReviewData.Rest_id,
        Upload_id: data.ReviewData.Upload_id,
      };
      await fobeAxios
        .post("/api/Daily/Get_lineitems", data, { headers: getToken() })
        .then((res) => {
          setLoadingaimapfull(false);
          var datwrfwea = res.data.data.Items;
          var budgetlist = [];
          datwrfwea.forEach((element) => {
            budgetlist.push({
              Invoice_id: element.invoice_id,
              old_cat_id: element.cat_id,
              new_cat_id: 0,
              old_cat_name: element.Fobecategory,
              new_cat_name: element.Fobecategory,
              new_Cat_amt: element.Total,
              old_Purveyor: element.PurveyorRawName,
              new_Purveyor: element.PurveyorRawName,
              old_Details: element.description,
              new_Details: element.description,
              upload_id: element.upload_id,
              invoice_no: res.data.data.Invoice_number,
              rest_id: element.rest_id,
            });
          });
          setCantChangeUpdateReview(budgetlist);
          var data1 = res.data.data.Items;
          var items = {
            invoice_number: res.data.data.Invoice_number,
            invoice_date: res.data.data.Invoice_date,
            Budget_year: res.data.data.Budget_year,
            PurveyorRawName: res.data.data.PurveyorRawName,
            Items: data1,
          };
          var data = { data: items };
          setrecordStateforMapping(data);
          var invoiceno = res.data.data;
          setInvoiceNumber(invoiceno.Invoice_number);
          setInvoicedate(invoiceno.Invoice_date);
          var response = [];
          response = res.data.data.Items.map((a) => { return { ...a, new_cat_id: 0 } });
          // setAiMapTableData([]);
          // setAimapFilteredData([]);
          setLoadingaimap(true);
          setTimeout(() => {
            setAiMapTableData(response);
            setAimapFilteredData(response);
            setLoadingaimap(false);
          }, 100);
          const sumOfIds = response.reduce((acc, obj) => {
            return acc + parseFloat(obj.Total ? obj.Total : 0);
          }, 0);
          setTotal(sumOfIds);
          // getAimappingCategatoryList(index);
        })
        .catch((err) => {
          setLoadingaimapfull(false);
        });
    } else {
      let index = data.ReviewData.Rest_id;
      let ReviewRecord = data.ReviewData;
      if (ReviewRecord.invoice_from == "SYSCO EDI") {
        if (data.rescan) {
          rescan = true;
        }
        await fobeAxios
          .get(
            "/api/SyscoEDI/GetSyscoInvoice_csv?InvoiceURL=" +
            ReviewRecord.filename +
            "&restaurantid=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setLoadingg("false");
            setAImapActiveClass(true);
            if (res.data.status == 1 || res.data.status == 2) {
              setrecordStateforMapping(res.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (res.data.duplicateinvoice != "") {
                // && !RestrictDuplicate
                if (data.ReviewData.file_status != 7) {
                  setIndicationOfSyscoDuplicateInvoice(true);
                } else {
                  toast.warning("Duplicate Invoice");
                }
              }
            } else {
              let emptyObj = {
                status: 1,
                message: "Success",
                data: {
                  invoice_number: "",
                  invoice_date: "",
                  Items: [],
                  OverallTax: 0,
                  OverallTotal: 0,
                  OverallStatus: false,
                  OverallReason: "",
                  PurveyorRawName: "",
                },
              };
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
              toast.error("No Data Available");
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            setLoadingg("false");
          });
      } else if (ReviewRecord.invoice_from == "PFG EDI") {
        if (data.rescan) {
          rescan = true;
        }
        await fobeAxios
          .get(
            "/api/PFSEDI/GetPFSInvoice_csv?InvoiceURL=" +
            ReviewRecord.filename +
            "&restaurantid=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setLoadingg("false");
            setAImapActiveClass(true);
            if (res.data.status == 1 || res.data.status == 2) {
              setrecordStateforMapping(res.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (res.data.duplicateinvoice != "") {
                // && !RestrictDuplicate
                if (data.ReviewData.file_status != 7) {
                  setIndicationOfSyscoDuplicateInvoice(true);
                } else {
                  toast.warning("Duplicate Invoice");
                }
              }
            } else {
              let emptyObj = {
                status: 1,
                message: "Success",
                data: {
                  invoice_number: "",
                  invoice_date: "",
                  Items: [],
                  OverallTax: 0,
                  OverallTotal: 0,
                  OverallStatus: false,
                  OverallReason: "",
                  PurveyorRawName: "",
                },
              };
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
              toast.error("No Data Available");
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            setLoadingg("false");
          });
      } else if (ReviewRecord.invoice_from == "MCLANE EDI") {
        if (data.rescan) {
          rescan = true;
        }
        await fobeAxios
          .get(
            "/api/McLane/GetMcLaneInvoice_edi?InvoiceURL=" +
            ReviewRecord.filename +
            "&restaurantid=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setLoadingg("false");
            setAImapActiveClass(true);
            if (res.data.status == 1 || res.data.status == 2) {
              setrecordStateforMapping(res.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (res.data.duplicateinvoice != "") {
                // && !RestrictDuplicate
                if (data.ReviewData.file_status != 7) {
                  setIndicationOfSyscoDuplicateInvoice(true);
                } else {
                  toast.warning("Duplicate Invoice");
                }
              }
            } else {
              let emptyObj = {
                status: 1,
                message: "Success",
                data: {
                  invoice_number: "",
                  invoice_date: "",
                  Items: [],
                  OverallTax: 0,
                  OverallTotal: 0,
                  OverallStatus: false,
                  OverallReason: "",
                  PurveyorRawName: "",
                },
              };
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
              toast.error("No Data Available");
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            setLoadingg("false");
          });
      } else if (ReviewRecord.invoice_from == "Benekeith EDI") {
        if (data.rescan) {
          rescan = true;
        }
        await fobeAxios
          .get(
            "api/Benekeith/Get_Benekeith_Csv?InvoiceURL=" +
            ReviewRecord.filename +
            "&rest_id=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setLoadingg("false");
            setAImapActiveClass(true);
            if (res.data.status == 1 || res.data.status == 2) {
              setrecordStateforMapping(res.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (res.data.duplicateinvoice != "") {
                // && !RestrictDuplicate
                if (data.ReviewData.file_status != 7) {
                  setIndicationOfSyscoDuplicateInvoice(true);
                } else {
                  toast.warning("Duplicate Invoice");
                }
              }
            } else {
              let emptyObj = {
                status: 1,
                message: "Success",
                data: {
                  invoice_number: "",
                  invoice_date: "",
                  Items: [],
                  OverallTax: 0,
                  OverallTotal: 0,
                  OverallStatus: false,
                  OverallReason: "",
                  PurveyorRawName: "",
                },
              };
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
              toast.error("No Data Available");
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            setLoadingg("false");
          });

      }
      else if (ReviewRecord.invoice_from == "USFOOD") {
        if (data.rescan) {
          rescan = true;
        }
        await fobeAxios
          .get(
            "api/USFEDI/GetUSFEDI_Invoice?InvoiceURL=" +
            ReviewRecord.filename +
            "&restaurantid=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setLoadingg("false");
            setAImapActiveClass(true);
            if (res.data.status == 1 || res.data.status == 2) {
              setrecordStateforMapping(res.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (res.data.duplicateinvoice != "") {
                // && !RestrictDuplicate
                if (data.ReviewData.file_status != 7) {
                  setIndicationOfSyscoDuplicateInvoice(true);
                } else {
                  toast.warning("Duplicate Invoice");
                }
              }
            } else {
              let emptyObj = {
                status: 1,
                message: "Success",
                data: {
                  invoice_number: "",
                  invoice_date: "",
                  Items: [],
                  OverallTax: 0,
                  OverallTotal: 0,
                  OverallStatus: false,
                  OverallReason: "",
                  PurveyorRawName: "",
                },
              };
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
              toast.error("No Data Available");
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            setLoadingg("false");
          });

      }
      else {
        let emptyObj = {};
        if (data?.rescan) {
          rescan = true;
        }
        if (data?.verify) {
          verify = true;
        }
        await fobeAxios
          .get(
            (ReviewRecord.file_reader === 1
              ? "/api/Daily/verifyreadfromdoc/?InvoiceURL="
              : "/api/Daily/DocIntelligence/?InvoiceURL=") +
            ReviewRecord.filename +
            "&restaurantid=" +
            ReviewRecord.Rest_id +
            "&uploadid=" +
            ReviewRecord.Upload_id +
            "&rescan=" +
            rescan +
            "&Verify=" +
            verify
          )
          .then((res) => {
            setTimeout(() => {
              setLoadingaimapfull(false);
            }, 250);
            setAImapActiveClass(true);

            if (res.data.status == 1) {
              setrecordStateforMapping(res?.data);
              var invoiceno = res.data.data;
              setInvoiceNumber(invoiceno.invoice_number);
              var temp_date =
                invoiceno.invoice_date == ""
                  ? new Date().toISOString().slice(0, 10)
                  : moment(invoiceno.invoice_date.slice(0, 10)).format(
                    "YYYY-MM-DD"
                  );
              temp_date =
                temp_date == "Invalid date"
                  ? moment(invoiceno.invoice_date.slice(0, 8)).format(
                    "YYYY-MM-DD"
                  )
                  : temp_date;
              setInvoicedate(temp_date);
              // setInvoicedate(invoiceno.invoice_date.split(" ")[0]);
              setPurveyor(res?.data.data.PurveyorRawName);

              if (res.data.message != "Success") {
                toast.warning(res.data.message);
              }
              // Duplicate Invoice Response
              if (res.data.duplicateinvoice !== "" && !exist_Processor) {
                if (data.ReviewData.file_status != 7) {
                  if (res.data.UI_CHANGES == 1) {
                    setIndicationOfDuplicateInvoice(true);
                  } else {
                    setIndicationOfSyscoDuplicateInvoice(true);
                  }
                  setexistImageFile(res.data?.ExistingImageFiles);
                  setexistSourceFile(res.data?.source_filename);
                  setduplicateImageFile(res.data?.duplicateImageFiles);
                } else {
                  setexistImageFile(res.data?.ExistingImageFiles);
                  setexistSourceFile(res.data?.source_filename);
                  setduplicateImageFile(res.data?.duplicateImageFiles);
                  toast.warning("Duplicate Invoice");
                }
              }
              var response = [];
              response = res.data.data.Items.map(item => {
                return { ...item, Fobecategory: item.MLAI, Cat_id: 0 }
              });
              setAiMapTableData([]);
              setAimapFilteredData([]);
              setTimeout(() => {
                setAiMapTableData(response);
                setAimapFilteredData(response);
                setLoadingaimap(false);
              }, 100);
              const sumOfIds = response.reduce((acc, obj) => {
                return acc + parseFloat(obj.Total ? obj.Total : 0);
              }, 0);
              setTotal(sumOfIds);
              // getAimappingCategatoryList(index);
              if (rescan == true) {
                toast.success("Rescanned Successfully");
              }
            } else {
              if (rescan == true) {
                toast.error("Rescan Failed");
                emptyObj = {
                  status: 1,
                  message: "Success",
                  data: {
                    invoice_number: "",
                    invoice_date: "",
                    Items: [],
                    OverallTax: 0,
                    OverallTotal: 0,
                    OverallStatus: false,
                    OverallReason: "",
                    PurveyorRawName: "",
                  },
                };
              } else {
                emptyObj = {
                  status: 1,
                  message: "Success",
                  data: {
                    invoice_number: "",
                    invoice_date: "",
                    Items: [],
                    OverallTax: 0,
                    OverallTotal: 0,
                    OverallStatus: false,
                    OverallReason: "",
                    PurveyorRawName: "",
                  },
                };

                setrecordStateforMapping(emptyObj);
                setInvoiceNumber(emptyObj.data.invoice_number);
                setInvoicedate(emptyObj.data.invoice_date);
                toast.error("No Data Available");
              }
              // getAimappingCategatoryList(index);
              setLoadingaimapfull(false);
              setrecordStateforMapping(emptyObj);
              setAiMapTableData([]);
              setAimapFilteredData([]);
            }
          })
          .catch((err) => {
            setLoadingaimapfull(false);
            // getAimappingCategatoryList(index);
            toast.error("No Data Available");
          });
      }
    }
  };
  //GET RESTAURANT LIST API
  const showrestlist = async () => {
    await fobeAxios.get("api/Daily/GetRestaurants_InvoiceAccess").then((res) => {
      let temp = [];
      res.data.rest_list.map((x, index) => {
        temp = [
          ...temp,
          { id: index, value: x.rest_id, label: x.company_name, option: [] },
        ];
      });

      setrestlistfordrop(temp);
    });
  };
  const [flag, setflag] = useState(false);
  useEffect(() => {
    //getData(updatedate);
    showrestlist();
    const storedUser =
      localStorage.getItem("fobeuser") === "1"
        ? localStorage.getItem("fobeusername")
        : localStorage.getItem("Username");
    if (storedUser) {
      setLoginUserName(storedUser);
    }
  }, []);

  const [Note, setNotes] = useState([]);
  const [newNote, setNewNote] = useState(null);
  const [noterecord, setNoterecord] = useState();
  const DeleteNotes = async (note) => {
    let d = {
      order_id: note.order_id,
      Rest_id: note.Rest_id,
      upload_id: note.upload_id,
    };
    await fobeAxios
      .post("api/Daily/Delete_invoicenotes", d)
      .then(() => {
        setNotes([]);
        getNotes(noterecord);
      })
      .catch(() => {
        console.log("Went Wrong");
      });
  };

  const uploadNewNotes = async () => {
    let d = {
      Rest_id: noterecord.Rest_id,
      upload_id: noterecord.Upload_id,
      notes_details: newNote,
    };

    await fobeAxios
      .post("api/Daily/upload_invoice_notes", d)
      .then(() => {
        setNewNote();
        setNotes([]);
        getNotes(noterecord);
      })
      .catch(() => {
        console.log("went wrong");
      });
  };
  const getNotes = async (record) => {
    setNoterecord(record);
    await fobeAxios(
      "api/Daily/getNotes/" + record.Rest_id + "/" + record.Upload_id
    )
      .then((d) => {
        setNotesPopup(true);
        setNotes(d.data.LatestNotes ? d.data.LatestNotes : []);
        check();
        handleDropdown5Change(dropdown5Value);
      })
      .catch(() => {
        console.log("Went Wrong");
      });
  };
  const check = () => { };

  const changeAcceptDenyStatus = async (key, record, index) => {
    let d = [];
    if (key !== 0) {
      //MLAI

      d = AiMapdData.filter(
        (x) =>
          x.label.toString().trim().toLowerCase() ===
          record.MLAI.toString().trim().toLowerCase()
      );
      if (d.length > 0) {
        key = key;
      } else {
        key = 0;
      }
    }
    record.MLAIStatus = key;

    await fobeAxios
      .post("api/Daily/SaveRawdata", record)
      .then(() => {
        if (key == 0) {
          let map = [...AiMapTableData];
          map[index].MLAIStatus = key;
          setAiMapTableData(map);
        } else {
          let map = [...AiMapTableData];
          map[index].MLAIStatus = key;
          map[index].MLAI = d[0].label;
          setAiMapTableData(map);
        }
      })
      .catch(() => { });
  };
  const handlechangeStatus = async () => {
    setFile_status(7);
    ReviewData.ReviewData.file_status = 7;

    let d = {
      Rest_id: ReviewData.ReviewData.Rest_id,
      upload_id: ReviewData.ReviewData.Upload_id,
      filestatus: 7,
    };

    await fobeAxios
      .post("api/Daily/save_invoice_status", d)
      .then(() => {
        toast.success("Status Changed To Duplicate Successfully");
        setIndicationOfDuplicateInvoice(false);
        setIndicationOfSyscoDuplicateInvoice(false);
        setFile_status(7);
        getData(updatedate);
        setReviewInvActiveClass(false);
        setAImapActiveClass(false);
        setLoadingReviewInvoice(false);
      })
      .catch(() => {
        console.log("status not saved");
      });
  };
  // useEffect(() => {
  //   check();
  // }, [dropdown1Value, dropdown2Value, dropdown3Value, dropdown4Value, flag]);

  const changingFileStatus = async (payload) => {
    try {
      await fobeAxios
        .post("api/Daily/save_invoice_status", payload)
        .then(async () => {
          // await getData();
          check();
          handleDropdown5Change(dropdown5Value);
          setTimeout(() => {
            setflag(!flag);
          }, 100);
        })
        .catch(() => {
          console.log("status not saved");
        });
    } catch (e) {
      console.log("status not saved");
    }
  };
  const nextAndPrevious = (key, index) => {
    setIsChecked(false);
    let currentpayload = {};
    let pastpayload = {};
    // handleDropdown5Change(dropdown5Value);
    // check();
    try {
      setMultiDeleteItems([]);
      setShowDeleteIcon(false);
      setAiMapTableData([]);
      if (key === 0) {
        if (parseInt(index) !== 0) {
          if (
            (tableData[index - 1].invoice_from == "From_Mail" &&
              tableData[index - 1].company == "") ||
            (tableData[index - 1].invoice_from != "From_Mail" &&
              tableData[index - 1].company == "")
          ) {
            nextAndPrevious(0, index - 1);
          } else {
            // if(tableData[index-1].file_status != 2 &&
            // (tableData[index-1].invoice_from =='From_Mail' || tableData[index-1].invoice_from !='From_Mail' && tableData[index-1].company !='' &&tableData[index-1].file_status != 2)){
            const d = {
              ReviewData: tableData[index - 1],
              index: index - 1,
            };
            currentpayload = {
              Rest_id: tableData[index - 1].Rest_id,
              upload_id: tableData[index - 1].Upload_id,
              filestatus:
                tableData[index].file_status == 1
                  ? 5
                  : tableData[index].file_status,
            };
            changingFileStatus(currentpayload);
            pastpayload = {
              Rest_id: FilestatuschangePreviousandNext.Rest_id,
              upload_id: FilestatuschangePreviousandNext.Upload_id,
              filestatus: FilestatuschangePreviousandNext.file_status,
            };
            changingFileStatus(pastpayload);
            getInvoiceDataUrl(d);
            setPreviousDisabled(false);
            setNextDisabled(false);
          }
          // else{
          //   nextAndPrevious(0, (index - 1))
          // }
        } else if (parseInt(index) === 0) {
          const d = {
            ReviewData: tableData[index],
            index: index,
          };
          currentpayload = {
            Rest_id: tableData[index].Rest_id,
            upload_id: tableData[index].Upload_id,
            filestatus:
              tableData[index].file_status == 1
                ? 5
                : tableData[index].file_status,
          };
          changingFileStatus(currentpayload);
          pastpayload = {
            Rest_id: FilestatuschangePreviousandNext.Rest_id,
            upload_id: FilestatuschangePreviousandNext.Upload_id,
            filestatus: FilestatuschangePreviousandNext.file_status,
          };
          changingFileStatus(pastpayload);
          getInvoiceDataUrl(d);
          setPreviousDisabled(true);
          setNextDisabled(false);
        }
      } else if (key === 1) {
        if (parseInt(index) !== tableData.length - 1) {
          if (
            (tableData[index + 1].invoice_from == "From_Mail" &&
              tableData[index + 1].company == "") ||
            (tableData[index + 1].invoice_from != "From_Mail" &&
              tableData[index + 1].company == "")
          ) {
            nextAndPrevious(1, index + 1);
          } else {
            // if(tableData[index + 1].file_status != 2 &&
            // (tableData[index + 1].invoice_from =='From_Mail' && tableData[index + 1].company !='')
            // || (tableData[index + 1].invoice_from !='From_Mail' && tableData[index + 1].company !='')){
            const d = {
              ReviewData: tableData[index + 1],
              index: index + 1,
            };
            currentpayload = {
              Rest_id: tableData[index + 1].Rest_id,
              upload_id: tableData[index + 1].Upload_id,
              filestatus:
                tableData[index].file_status == 1
                  ? 5
                  : tableData[index].file_status,
            };
            changingFileStatus(currentpayload);
            pastpayload = {
              Rest_id: FilestatuschangePreviousandNext.Rest_id,
              upload_id: FilestatuschangePreviousandNext.Upload_id,
              filestatus: FilestatuschangePreviousandNext.file_status,
            };
            changingFileStatus(pastpayload);
            getInvoiceDataUrl(d);
            setNextDisabled(false);
            setPreviousDisabled(false);
          }
          // else{
          //   nextAndPrevious(1, (index + 1))
          // }
        } else if (parseInt(index) === tableData.length - 1) {
          const d = {
            ReviewData: tableData[index],
            index: index,
          };
          currentpayload = {
            Rest_id: tableData[index].Rest_id,
            upload_id: tableData[index].Upload_id,
            filestatus:
              tableData[index].file_status == 1
                ? 5
                : tableData[index].file_status,
          };
          changingFileStatus(currentpayload);
          pastpayload = {
            Rest_id: FilestatuschangePreviousandNext.Rest_id,
            upload_id: FilestatuschangePreviousandNext.Upload_id,
            filestatus: FilestatuschangePreviousandNext.file_status,
          };
          changingFileStatus(pastpayload);
          getInvoiceDataUrl(d);
          setNextDisabled(true);
          setPreviousDisabled(false);
        }
      }
    } catch (e) {
      setLoadingaimapfull(false);
    }
  };

  const foronlydisabled = (i) => {
    if (i == 0) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }
    if (i == dataForPreandNext.length - 1) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  };

  const selectAiMapping = (value, index, statistics) => {
    let d = AiMapdData.filter((x) => x.value == value);
    if (d.length > 0) {
      let arr = [...AiMapTableData];
      if (ReviewData?.ReviewData?.file_status == 2) {
        arr[index].Fobecategory = d[0].label;
        arr[index].new_cat_id = Number(d[0].value.split('_')[1]);
      } else {
        if (statistics) {
          if (statistics != value.split('_')[0]) {
            setmappingInfo(true)
            setStatistics(statistics)
            setChangedLineItemList({
              index: index,
              Category: value
            });
            return;
          }
        }
        if (arr[index].MLAI.toLowerCase() == d[0].label.toLowerCase()) {
          arr[index].Fobecategory = d[0].label;
          arr[index].Cat_id = Number(d[0].value.split('_')[1]);
          arr[index].MLAIStatus = 1;
        } else {
          arr[index].Fobecategory = d[0].label;
          arr[index].Cat_id = Number(d[0].value.split('_')[1]);
          arr[index].MLAIStatus = 0;
        }
      }
      setAiMapTableData(arr);
      if (ReviewData?.ReviewData?.file_status == 2) {
        CantChangeUpdateReview[index].new_cat_name = d[0].label;
        CantChangeUpdateReview[index].new_cat_id = Number(d[0].value.split('_')[1]);
      }
    }
  };
  const changeCategory = (list) => {
    setmappingInfo(false);
    let d = AiMapdData.filter((x) => x.value == list.Category);
    let arr = [...AiMapTableData];
    if (d.length > 0) {
      if (arr[list.index].MLAI.toLowerCase() == d[0].label.toLowerCase()) {
        arr[list.index].Fobecategory = d[0].label;
        arr[list.index].Cat_id = Number(d[0].value.split('_')[1]);
        arr[list.index].MLAIStatus = 1;
      } else {
        arr[list.index].Fobecategory = d[0].label;
        arr[list.index].Cat_id = Number(d[0].value.split('_')[1]);
        arr[list.index].MLAIStatus = 0;
      }
    }
  }
  const Total = () => {
    const sumOfIds = AiMapTableData.reduce((acc, obj) => {
      return acc + parseFloat(obj.Total ? obj.Total : 0);
    }, 0);
    setTotal(sumOfIds);
  };
  const onChangeamount = (value, i, record) => {
    if (
      value.target.value == undefined ||
      value.target.value == "" ||
      value.target.value == null
    ) {
      let temp = [...AiMapTableData];
      temp[i].Total = "";
      setAiMapTableData(temp);
      Total();
    } else if (
      typeof value.target.value === "number" ||
      (typeof value.target.value === "string" &&
        /^[0-9.-]+$/.test(value.target.value))
    ) {
      let temp = [...AiMapTableData];
      temp[i].Total = value.target.value;
      setAiMapTableData(temp);
      Total();
    }
  };

  const items = [
    { key: "1", label: "Yet to Start", disabled: false },
    { key: "5", label: "Processing", disabled: false },
    { key: "2", label: "Completed", disabled: true },
    { key: "3", label: "Re-scan", disabled: false },
    { key: "4", label: "Flagged", disabled: false },
    { key: "6", label: "Re-Scanned", disabled: true },
    { key: "7", label: "Duplicate", disabled: true },
  ];
  // AiMapping
  const [AiMapdData, setAiMapData] = useState([]);
  const [recorddelete, setrecorddelete] = useState("");
  const [recorddeleteindex, setrecorddeleteindex] = useState("");
  const [tempData, settempData] = useState([]);
  const [Loadingaimap, setLoadingaimap] = useState(false);
  const [Loadingaimapfull, setLoadingaimapfull] = useState(false);
  const [LoadingReviewInvoice, setLoadingReviewInvoice] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const [MultiDeleteItems, setMultiDeleteItems] = useState([]);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [FilestatusChange, setFilestatusChange] = useState("");
  const [FilestatuschangePreviousandNext, setFilestatuschangePreviousandNext] =
    useState();
  const AiMapColumn =
    ReviewData?.ReviewData?.file_status == 2
      ? [
        {
          title: (
            <div>
              <Checkbox
                onChange={(e) => multicheckk(e.target.checked)}
                checked={isChecked}
              ></Checkbox>
            </div>
          ),
          dataIndex: "cat_id",
          width: 10,
          sortOrder: false,
          align: "center",
          key: "cat_id",
          // sorter: (a, b) => a.cat_id - b.cat_id,
          render: (data, record, index) => {
            const multicheck = (checked, index) => {
              if (checked) {
                // Add index to the array
                setMultiDeleteItems((prevItems) => [...prevItems, index]);
              } else {
                // Remove index from the array
                setMultiDeleteItems((prevItems) =>
                  prevItems.filter((item) => item !== index)
                );
              }
              // Determine whether to show delete icon based on the length of MultiDeleteItems array
              // setShowDeleteIcon(MultiDeleteItems.length > 1 ? true : false);
            };
            return (
              <>
                <Checkbox
                  checked={MultiDeleteItems.includes(index)}
                  onChange={(event) =>
                    multicheck(event.target.checked, index)
                  }
                />
              </>
            );
          },
        },
        {
          title: "FOBE CATEGORY",
          dataIndex: "Fobecategory",
          width: 70,
          align: "center",
          key: "Fobecategory",
          sorter: (a, b) => {
            if (a.Fobecategory && b.Fobecategory) {
              return a.Fobecategory.localeCompare(b.Fobecategory);
            } else {
              return 0; // or any other default value
            }
          },
          render: (data, record, index) => {
            let selectedOption1 = [];
            if (data !== "" && data !== null && data !== undefined) {
              selectedOption1 = AiMapdData.filter(
                (option) =>
                  option.label.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
            }
            return (
              <>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={AiMapdData}
                  onChange={(v) => {
                    selectAiMapping(v, index, record.Statistics);
                  }}
                  value={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  defaultValue={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  dropdownRender={(menu) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                      onClick={(e) => e.stopPropagation()} // Prevents the dropdown from closing when clicking inside
                      style={{ textAlign: "left" }}
                    >
                      {menu}
                    </div>
                  )}
                  onClick={() => setIsTableScrolled(false)}
                  getPopupContainer={(triggerNode) =>
                    isTableScrolled ? triggerNode.parentNode : document.body
                  }
                />
              </>
            );
          },
        },

        {
          title: "AMOUNT",
          dataIndex: "Total",
          width: 40,
          align: "center",
          key: "Total",
          sorter: (a, b) => a.Total.localeCompare(b.Total),
          render: (data, record, index) => {
            return (
              <>
                <div style={{ paddingLeft: "1em" }}>
                  <span class="input-group-addondolar">$</span>
                  <Input
                    defaultValue={data}
                    value={data}
                    onChange={(value) => onChangeamount(value, index, record)}
                    onClick={(s) => {
                      if (s.target.value == 0 || s.target.value == "0") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "";
                        setAiMapTableData(temp);
                      }
                    }}
                    onBlur={(s) => {
                      if (s.target.value == "") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "0";
                        setAiMapTableData(temp);
                      }
                    }}
                    type="text"
                    step="0.00"
                    maxLength="10"
                  />
                </div>
              </>
            );
          },
        },
        {
          title: "DETAILS",
          dataIndex: "description",
          width: 100,
          align: "center",
          key: "description",
          sorter: (a, b) => a.description.localeCompare(b.description),
          render: (data, record, index) => {
            const onChangedescription = (value, i, record) => {
              AiMapTableData[i].description = value.target.value;
              setAiMapTableData(AiMapTableData);
              // if(!SendtoUpadteInvoice.includes(i)){
              //   SendtoUpadteInvoice.push(i);
              // }
            };
            return (
              <>
                <Input
                  defaultValue={data}
                  type="text"
                  onChange={(value) =>
                    onChangedescription(value, index, record)
                  }
                />
              </>
            );
          },
        },
        {
          title: "DELETE",
          dataIndex: "day_id",
          width: 30,
          align: "center",
          key: "day_id",
          sorter: (a, b) => a.day_id.localeCompare(b.day_id),
          render: (data, record, index) => {
            return (
              <>
                <Tooltip title="Delete Item">
                  <FontAwesomeIcon
                    onClick={(value) => {
                      setShowdelete(true);
                      setrecorddeleteindex(index);
                      setrecorddelete(record);
                      setdetetecatid(record.cat_id);
                      setdeteteinvoiceid(record.invoice_id);
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "1.3em",
                    }}
                    icon={faTrashCan}
                  />
                </Tooltip>
              </>
            );
          },
        },
      ]
      : [
        {
          title: (
            <div>
              <Checkbox
                onChange={(e) => multicheckk(e.target.checked)}
                checked={isChecked}
              ></Checkbox>
            </div>
          ),
          sortOrder: false,
          dataIndex: "Ruid",
          width: 10,
          align: "center",
          key: "Ruid",
          // sorter: (a, b) => a.Ruid - b.Ruid,
          render: (data, record, index) => {
            const multicheck = (checked, index) => {
              if (checked) {
                // Add index to the array
                setMultiDeleteItems((prevItems) => [...prevItems, index]);
              } else {
                // Remove index from the array
                setMultiDeleteItems((prevItems) =>
                  prevItems.filter((item) => item !== index)
                );
              }
              // Determine whether to show delete icon based on the length of MultiDeleteItems array
            };
            return (
              <>
                <Checkbox
                  checked={MultiDeleteItems.includes(index)}
                  onChange={(event) =>
                    multicheck(event.target.checked, index)
                  }
                />
              </>
            );
          },
        },
        {
          title: "FOBE CATEGORY",
          dataIndex: "Fobecategory",
          width: 70,
          align: "center",
          key: "Fobecategory",
          sorter: (a, b) => {
            if (a.MLAI && b.MLAI) {
              return a.MLAI.localeCompare(b.MLAI);
            } else {
              return 0; // or any other default value
            }
          },
          render: (data, record, index) => {
            let selectedOption1 = [];
            if (data !== "" && data !== null && data !== undefined) {
              selectedOption1 = AiMapdData.filter(
                (option) =>
                  option.label.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
              if (selectedOption1 == "") {
                AiMapTableData[index].Fobecategory = "";
              }
            }
            return (
              <>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  disabled={record.categorydisable == 1}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={AiMapdData}
                  onChange={(v) => {
                    selectAiMapping(v, index, record.Statistics);
                  }}
                  value={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  defaultValue={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  dropdownRender={(menu) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                      onClick={(e) => {
                        e.stopPropagation();
                      }} // Prevents the dropdown from closing when clicking inside
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {menu}
                    </div>
                  )}
                  onClick={() => setIsTableScrolled(false)}
                  getPopupContainer={(triggerNode) =>
                    isTableScrolled ? triggerNode.parentNode : document.body
                  }
                />
              </>
            );
          },
        },

        {
          title: "AMOUNT",
          dataIndex: "Total",
          width: 40,
          align: "center",
          key: "Total",
          sorter: (a, b) => a.Total.localeCompare(b.Total),
          render: (data, record, index) => {
            return (
              <>
                <div style={{ paddingLeft: "1em" }}>
                  <span class="input-group-addondolar">$</span>
                  <Input
                    defaultValue={data}
                    value={data}
                    type="text"
                    onChange={(value) => onChangeamount(value, index, record)}
                    onClick={(s) => {
                      if (s.target.value == 0 || s.target.value == "0") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "";
                        setAiMapTableData(temp);
                      }
                    }}
                    onBlur={(s) => {
                      if (s.target.value == "") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "0";
                        setAiMapTableData(temp);
                      }
                    }}
                    step="0.00"
                    maxLength="10"
                  />
                </div>
              </>
            );
          },
        },
        {
          title: "DETAILS",
          dataIndex: "description",
          width: 100,
          align: "center",
          key: "description",
          sorter: (a, b) => a.description.localeCompare(b.description),
          render: (data, record, index) => {
            const onChangedescription = (value, i, record) => {
              AiMapTableData[i].description = value.target.value;
              setAiMapTableData(AiMapTableData);
            };
            return (
              <>
                <Input
                  defaultValue={data}
                  type="text"
                  onChange={(value) =>
                    onChangedescription(value, index, record)
                  }
                />
              </>
            );
          },
        },
        {
          title: "DELETE",
          dataIndex: "Section",
          width: 30,
          align: "center",
          key: "Section",
          sorter: (a, b) => a.Section.localeCompare(b.Section),
          render: (data, record, index) => {
            return (
              <>
                <Tooltip title="Delete Item">
                  <FontAwesomeIcon
                    onClick={(value) => {
                      setShowdelete(true);
                      setrecorddeleteindex(index);
                      setrecorddelete(record);
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "1.3em",
                    }}
                    icon={faTrashCan}
                  />
                </Tooltip>
              </>
            );
          },
        },
      ];
  const ReviewColumn =
    ReviewData?.ReviewData?.file_status == 2
      ? [
        {
          title: "FOBE CATEGORY",
          dataIndex: "Fobecategory",
          sortOrder: false,
          width: 50,
          align: "center",
          key: "Fobecategory",
          sorter: (a, b) => {
            if (a.Fobecategory && b.Fobecategory) {
              return a.Fobecategory.localeCompare(b.Fobecategory);
            } else {
              return 0; // or any other default value
            }
          },
          render: (data, record, index) => {
            let selectedOption1 = [];
            if (data !== "" && data !== null && data !== undefined) {
              selectedOption1 = AiMapdData.filter(
                (option) =>
                  option.label.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
              // if (selectedOption1.length > 0) {
              // } else {
              //   AiMapdData.push({ value: data, label: data });
              // }
              if (selectedOption1 == "") {
                AiMapTableData[index].Fobecategory = "";
              }
            }

            return (
              <>
                <Select
                  showSearch
                  disabled
                  style={{ width: "90%" }}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={AiMapdData}
                  onChange={(v) => {
                    selectAiMapping(v, index, record.Statistics);
                  }}
                  value={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  defaultValue={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  dropdownRender={(menu) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                      onClick={(e) => e.stopPropagation()} // Prevents the dropdown from closing when clicking inside
                      style={{ textAlign: "left" }}
                    >
                      {menu}
                    </div>
                  )}
                  onClick={() => setIsTableScrolled(false)}
                  getPopupContainer={(triggerNode) =>
                    isTableScrolled ? triggerNode.parentNode : document.body
                  }
                />
              </>
            );
          },
        },
        {
          title: "AMOUNT",
          dataIndex: "Total",
          width: 40,
          align: "center",
          key: "Total",
          sorter: (a, b) => a.Total.localeCompare(b.Total),
          render: (data, record, index) => {
            return (
              <>
                <div style={{ paddingLeft: "1em" }}>
                  <span class="input-group-addondolar">$</span>
                  <InputNumber
                    disabled
                    defaultValue={data}
                    onChange={(value) => onChangeamount(value, index, record)}
                    onClick={(s) => {
                      if (s.target.value == 0 || s.target.value == "0") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "";
                        setAiMapTableData(temp);
                      }
                    }}
                    onBlur={(s) => {
                      if (s.target.value == "") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "0";
                        setAiMapTableData(temp);
                      }
                    }}
                    step="0.00"
                    type="number"
                    maxLength="10"
                    stringMode
                  />
                </div>
              </>
            );
          },
        },
        {
          title: "DETAILS",
          dataIndex: "description",
          width: 100,
          align: "center",
          key: "description",
          sorter: (a, b) => a.description.localeCompare(b.description),
          render: (data, record, index) => {
            const onChangedescription = (value, i, record) => {
              AiMapTableData[i].description = value.target.value;
              setAiMapTableData(AiMapTableData);
            };
            return (
              <>
                <Input
                  defaultValue={data}
                  disabled
                  type="text"
                  onChange={(value) =>
                    onChangedescription(value, index, record)
                  }
                />
              </>
            );
          },
        },
      ]
      : [
        {
          title: "FOBE CATEGORY",
          dataIndex: "Fobecategory",
          sortOrder: false,
          width: 50,
          align: "center",
          key: "Fobecategory",
          sorter: (a, b) => {
            if (a.MLAI && b.MLAI) {
              return a.MLAI.localeCompare(b.MLAI);
            } else {
              return 0; // or any other default value
            }
          },
          render: (data, record, index) => {
            let selectedOption1 = [];
            if (data !== "" && data !== null && data !== undefined) {
              selectedOption1 = AiMapdData.filter(
                (option) =>
                  option.label.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
              if (selectedOption1 == "") {
                AiMapTableData[index].Fobecategory = "";
              }
            }

            return (
              <>
                <Select
                  showSearch
                  disabled
                  style={{ width: "90%" }}
                  placeholder="Select Category"
                  // optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={AiMapdData}
                  onChange={(v) => {
                    selectAiMapping(v, index, record.Statistics);
                  }}
                  value={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  defaultValue={
                    data == "" || data == null || data == undefined
                      ? []
                      : selectedOption1
                  }
                  dropdownRender={(menu) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                      onClick={(e) => e.stopPropagation()} // Prevents the dropdown from closing when clicking inside
                      style={{ textAlign: "left" }}
                    >
                      {menu}
                    </div>
                  )}
                  onClick={() => setIsTableScrolled(false)}
                  getPopupContainer={(triggerNode) =>
                    isTableScrolled ? triggerNode.parentNode : document.body
                  }
                />
              </>
            );
          },
        },

        {
          title: "AMOUNT",
          dataIndex: "Total",
          width: 40,
          align: "center",
          key: "Total",
          sorter: (a, b) => a.Total.localeCompare(b.Total),
          render: (data, record, index) => {
            return (
              <>
                <div style={{ paddingLeft: "1em" }}>
                  <span class="input-group-addondolar">$</span>
                  <InputNumber
                    disabled
                    defaultValue={data}
                    onChange={(value) => onChangeamount(value, index, record)}
                    onClick={(s) => {
                      if (s.target.value == 0 || s.target.value == "0") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "";
                        setAiMapTableData(temp);
                      }
                    }}
                    onBlur={(s) => {
                      if (s.target.value == "") {
                        let temp = [...AiMapTableData];
                        temp[index].Total = "0";
                        setAiMapTableData(temp);
                      }
                    }}
                    step="0.00"
                    type="number"
                    maxLength="10"
                    stringMode
                  />
                </div>
              </>
            );
          },
        },
        {
          title: "DETAILS",
          dataIndex: "description",
          width: 100,
          align: "center",
          key: "description",
          sorter: (a, b) => a.description.localeCompare(b.description),
          render: (data, record, index) => {
            const onChangedescription = (value, i, record) => {
              AiMapTableData[i].description = value.target.value;
              setAiMapTableData(AiMapTableData);
            };
            return (
              <>
                <Input
                  disabled
                  defaultValue={data}
                  type="text"
                  onChange={(value) =>
                    onChangedescription(value, index, record)
                  }
                />
              </>
            );
          },
        },
      ];
  const BackToInvoiceList = async () => {
    setIsChecked(false);
    setIndicationOfDuplicateInvoice(false);
    // setSearchText("");
    let d = {
      Rest_id: FilestatuschangePreviousandNext.Rest_id,
      upload_id: FilestatuschangePreviousandNext.Upload_id,
      filestatus:
        FilestatuschangePreviousandNext.file_status == 5
          ? 1
          : FilestatuschangePreviousandNext.file_status,
    };
    await fobeAxios
      .post("api/Daily/save_invoice_status", d)
      .then(() => {
        //   getData();
        check();
        handleDropdown5Change(dropdown5Value);
        // setTimeout(() => {
        //   setflag(!flag);
        // }, 100);
      })
      .catch(() => {
        console.log("status not saved");
      });
    setAImapActiveClass(false);
    setReviewInvActiveClass(false);
    setAiMapTableData([]);
    setPreviousDisabled(false);
    setNextDisabled(false);
  };
  const addNewLineItem = () => {
    if (AiMapTableData != undefined || AiMapTableData != "") {
      setLoadingaimap(true);
      var line = AiMapTableData.length;
      var newline = {
        AI: null,
        Fobecategory: "",
        MLAI: "",
        MLAIStatus: 2,
        OpenAi: "DISABLE",
        OpenAiStatus: 2,
        Price: "0",
        RowReason: "Please check Category",
        RowStatus: "False",
        Ruid: AiMapTableData == "" ? 1 : AiMapTableData[line - 1].Ruid + 1,
        Section: "",
        Total: "0",
        Type: "Unknown",
        Unit: "0",
        description: "",
        measuring_unit: null,
      };
      AiMapTableData.push(newline);
      setAiMapTableData([]);
      setTimeout(() => {
        setAiMapTableData(AiMapTableData);
        setLoadingaimap(false);
      }, 100);
    }
  };
  const Multideleteitem = async () => {
    // setShowDeleteIcon(false);
    const [year, month, day] =
      recordStateforMapping.data.invoice_date.split("-");
    let deletetemp = [];
    let url;
    if (ReviewFullData.file_status === 2) {
      url = "/Api/Daily/invoiceai_delete/";
      MultiDeleteItems.map((i) => {
        deletetemp = [
          ...deletetemp,
          {
            RestID: ReviewFullData.Rest_id.toString(),
            UploadID: ReviewFullData.Upload_id.toString(),
            Category: AiMapTableData[i].Fobecategory,
            Price:
              AiMapTableData[i].Total == "" ||
                AiMapTableData[i].Total == undefined ||
                AiMapTableData[i].Total == null
                ? 0
                : AiMapTableData[i].Total,
            Purveyor: recordStateforMapping.data.PurveyorRawName,
            Details: AiMapTableData[i].description,
            FailedReason: AiMapTableData[i].RowReason,
            Cat_id: AiMapTableData[i].cat_id,
            day_id: parseInt(day),
            month_id: parseInt(month),
            year_id: parseInt(year),
            InvoiceID: AiMapTableData[i].invoice_id,
          },
        ];
      });
    } else {
      MultiDeleteItems.map((i) => {
        url = "/api/Daily/Delete_InvoiceCategories/multicategory/";
        deletetemp = [
          ...deletetemp,
          {
            RestID: ReviewFullData.Rest_id.toString(),
            UploadID: ReviewFullData.Upload_id.toString(),
            Category: AiMapTableData[i].Fobecategory,
            Price:
              AiMapTableData[i].Total == "" ||
                AiMapTableData[i].Total == undefined ||
                AiMapTableData[i].Total == null
                ? 0
                : AiMapTableData[i].Total,
            Purveyor: recordStateforMapping.data.PurveyorRawName,
            Details: AiMapTableData[i].description,
            FailedReason: AiMapTableData[i].RowReason,
          },
        ];
      });
    }
    var data = { delete_multicategory: deletetemp };
    setLoadingaimap(true);
    await fobeAxios
      .post(url, data, {
        headers: getToken(),
      })
      .then((res) => {
        if (res.data.status == 1) {
          const myData = AiMapTableData.filter((item, index) => {
            return !MultiDeleteItems.includes(index);
          });
          setAiMapTableData([]);
          setTimeout(() => {
            setAiMapTableData(myData);
            setLoadingaimap(false);
          }, 100);
          toast.success("Deleted successfully.");
          const sumOfIds = myData.reduce((acc, obj) => {
            return acc + parseFloat(obj.Total ? obj.Total : 0);
          }, 0);
          setTotal(sumOfIds);
          setMultiDeleteItems([]);
          setShowDeleteIcon(false);
          setIsChecked(false);
        } else {
          toast.error("Deleted failed.");
          setAiMapTableData([]);
          setTimeout(() => {
            setAiMapTableData(AiMapTableData);
            setLoadingaimap(false);
          }, 100);
          setMultiDeleteItems([]);
          setShowDeleteIcon(false);
          setIsChecked(false);
        }
      })
      .catch(() => {
        setLoadingaimap(false);
        console.log("Went Wrong");
        setMultiDeleteItems([]);
        setShowDeleteIcon(false);
        setIsChecked(false);
      });
  };
  const DeleteRowItem = async (rec, i) => {
    const [year, month, day] =
      recordStateforMapping.data.invoice_date.split("-");
    setShowdelete(false);
    let body, url;
    if (ReviewFullData.file_status === 2) {
      url = "/Api/Daily/invoiceai_delete/";
      body = {
        delete_multicategory: [
          {
            RestID: ReviewFullData.Rest_id?.toString(),
            UploadID: ReviewFullData.Upload_id?.toString(),
            Category: AiMapTableData[i].Fobecategory,
            Price:
              AiMapTableData[i].Total == "" ||
                AiMapTableData[i].Total == undefined ||
                AiMapTableData[i].Total == null
                ? 0
                : AiMapTableData[i].Total,
            Purveyor: recordStateforMapping.data.PurveyorRawName,
            Details: AiMapTableData[i].description,
            FailedReason: AiMapTableData[i].RowReason,
            Cat_id: recorddelete.cat_id,
            day_id: parseInt(day),
            month_id: parseInt(month),
            year_id: parseInt(year),
            InvoiceID: recorddelete.invoice_id,
          },
          // Add more objects for additional categories if needed
        ],
      };
    } else {
      url = "/api/Daily/Delete_InvoiceCategories/singlecategory/";
      body = {
        RestID: ReviewFullData.Rest_id.toString(),
        UploadID: ReviewFullData.Upload_id.toString(),
        Category: AiMapTableData[i].Fobecategory,
        Price:
          AiMapTableData[i].Total == "" ||
            AiMapTableData[i].Total == undefined ||
            AiMapTableData[i].Total == null
            ? 0
            : AiMapTableData[i].Total,
        Purveyor: recordStateforMapping.data.PurveyorRawName,
        Details: AiMapTableData[i].description,
        FailedReason: AiMapTableData[i].RowReason,
      };
    }

    setLoadingaimap(true);
    await fobeAxios
      .post(url, body, {
        headers: getToken(),
      })
      .then((res) => {
        if (res.data.status == 1) {
          const myData = AiMapTableData.filter((item, index) => index !== i);
          setAiMapTableData([]);
          setTimeout(() => {
            setAiMapTableData(myData);
            setLoadingaimap(false);
          }, 100);
          toast.success("Deleted successfully.");
          const sumOfIds = myData.reduce((acc, obj) => {
            return acc + parseFloat(obj.Total ? obj.Total : 0);
          }, 0);
          setTotal(sumOfIds);
        } else {
          setAiMapTableData([]);
          setTimeout(() => {
            setAiMapTableData(AiMapTableData);
            setLoadingaimap(false);
          }, 100);
          toast.error("Deleted Failed");
        }
      })
      .catch(() => {
        setLoadingaimap(false);
        console.log("Went Wrong");
      });
  };
  const getAimappingCategatoryList = async (data) => {
    // let responseAi = res;
    var budget_month = new Date().getMonth() + 1;
    var budge_date = new Date().getDate();
    var resid = data;
    var budget_year = String(new Date().getFullYear());
    await fobeAxios
      .get(
        "/Api/Daily/GetInvoice_foroperations/" +
        budget_year +
        "/" +
        budget_month +
        "/" +
        budge_date +
        "/" +
        resid
      )
      .then(async (res) => {
        var data = res.data.Invoice_details;
        let catlist = [];
        data.map((x, index) => {
          catlist = [
            ...catlist,
            {
              value: `${x.Cat_name}_${x.Cat_id}`,
              label: x.Cat_name,
              key: index,
            },
          ];
        });
        setAiMapData(catlist);
      });
  };
  const FinishToInvoice = async () => {
    setLoadingReviewInvoice(true);
    if (ReviewData?.ReviewData?.file_status == 2) {
      var list = [];
      var invoicedate = recordStateforMapping.data.invoice_date;
      invoicedate = invoicedate.substring(0, 10);
      if (invoicedate.includes("/")) {
        invoicedate = invoicedate.split("/");
        var invoiceyear = invoicedate[2];
        var invoicemonth = invoicedate[0];
        var invoicedateval = invoicedate[1];
      } else {
        invoicedate = invoicedate.split("-");
        var invoiceyear = invoicedate[0];
        var invoicemonth = invoicedate[1];
        var invoicedateval = invoicedate[2];
      }
      CantChangeUpdateReview.forEach((a, i) => {
        CantChangeUpdateReview[i].new_Purveyor =
          recordStateforMapping.data.PurveyorRawName;
        CantChangeUpdateReview[i].new_cat_id = CantChangeUpdateReview[i].new_cat_id == 0 ?
          CantChangeUpdateReview[i].old_cat_id : AiMapTableData[i].new_cat_id;
        CantChangeUpdateReview[i].new_Details = AiMapTableData[i].description;
        CantChangeUpdateReview[i].new_Cat_amt = AiMapTableData[i].Total;
        CantChangeUpdateReview[i].invoice_no =
          recordStateforMapping.data.invoice_number;
        list.push(CantChangeUpdateReview[i]);
      });

      var budyr = [
        { budget_year: invoiceyear },
        { budget_month: invoicemonth },
        { budget_date: invoicedateval },
      ];
      var data = {
        Budget: list,
        Budget_details: budyr,
      };
      await fobeAxios
        .post("/api/Daily/updateInvoice_ai_check", data, {
          headers: getToken(),
        })
        .then((res) => {
          if (res.data == 1) {
            setLoadingReviewInvoice(false);
            toast.success("Invoice updated Successfully!");
            getData(updatedate);
            check();
            setReviewInvActiveClass(false);
            setAImapActiveClass(false);
            // setSendtoUpadteInvoice([]);
          } else {
            toast.error("Invoice updation Failed!");
            setLoadingReviewInvoice(false);
            getData(updatedate);
            check();
            setReviewInvActiveClass(false);
            setAImapActiveClass(false);
            // setSendtoUpadteInvoice([]);
          }
        })
        .catch(() => {
          setLoadingReviewInvoice(false);
          // setSendtoUpadteInvoice([]);
          toast.error("Invoice Updation Failed!");
        });
    } else {
      var items = [];
      AiMapTableData.forEach((element) => {
        items.push({
          id: "",
          item_purveyor: recordStateforMapping.data.PurveyorRawName,
          item_name: element.Fobecategory.includes('_') ? element.Fobecategory : element.Fobecategory?.split("_")[0],
          item_cat_id: element.Cat_id == 0 ?
            Number(AiMapdData?.find(a => a.label.toLocaleLowerCase() == element.Fobecategory.toLocaleLowerCase())?.value.split('_')[1])
            : element.Cat_id,
          // Number(element.Fobecategory.split("_")[1]),
          item_amount: element.Total,
          item_details: element.description,
          OpenAi: element.OpenAi,
          OpenAiStatus: element.OpenAiStatus,
          MLAI: element.MLAI,
          MLAIStatus: element.MLAIStatus == 2 ? 1 : element.MLAIStatus,
          rid: element.Ruid,
          prediction_from: element.prediction_from,
          measuring_unit: element.measuring_unit,
          Unit: element.Unit,
          ProductID: element?.ProductID,
        });
      });
      var invoicedate = recordStateforMapping.data.invoice_date;
      setPurveyor(recordStateforMapping.data?.PurveyorRawName);
      invoicedate = invoicedate.split(" ")[0];
      invoicedate = invoicedate.substring(0, 10);
      if (invoicedate.includes("/")) {
        invoicedate = invoicedate.split("/");
        var invoiceyear = invoicedate[2];
        var invoicemonth = invoicedate[0];
        var invoicedateval = invoicedate[1];
      } else {
        invoicedate = invoicedate.split("-");
        var invoiceyear = invoicedate[0];
        var invoicemonth = invoicedate[1];
        var invoicedateval = invoicedate[2];
      }
      var budgetlist = [
        { budget_year: invoiceyear },
        { budget_month: invoicemonth },
        { budget_date: invoicedateval },
        { uploadid: ReviewFullData.Upload_id.toString() },
        { Rest_id: ReviewFullData.Rest_id.toString() },
        { invoice_no: recordStateforMapping.data.invoice_number },
      ];
      var data = {
        Budget: items,
        Budget_details: budgetlist,
      };
      await fobeAxios
        .post("/Api/Daily/new_addInvoiceDsr", data, { headers: getToken() })
        .then((res) => {
          if (res.data.result == 1) {
            setLoadingReviewInvoice(false);
            toast.success("Data saved Successfully!");
            getData(updatedate);
            // check();
            setReviewInvActiveClass(false);
            setAImapActiveClass(false);
          } else {
            if (res.data.result == 2) {
              setLoadingReviewInvoice(false);
              // getData(updatedate);
              // check();
              // setReviewInvActiveClass(false);
              // setAImapActiveClass(false);
              if (res.data.UICHANGES_KEY == 1) {
                setIndicationOfDuplicateInvoice(true);
              } else {
                setIndicationOfSyscoDuplicateInvoice(true);
              }
              setexistImageFile(res.data.ExistingImageFiles);
              setexistSourceFile(res.data.source_filename);
              setduplicateImageFile(res.data.duplicateImageFiles);
            } else if (
              res.data.result == 0 &&
              data.res.message ==
              "Failed-Invoice date is not Valid(not in the range of any specific budget year)"
            ) {
              toast.error(
                "Invoice date is not Valid (not in the range of any specific budget year)!"
              );
              setLoadingReviewInvoice(false);
              getData(updatedate);
              // check();
              setReviewInvActiveClass(false);
              setAImapActiveClass(false);
            } else if (
              res.data.result == 0 &&
              data.res.message ==
              "Failed-13 period start date is not specified for this year"
            ) {
              toast.error(data.res.message);
              setLoadingReviewInvoice(false);
              getData(updatedate);
              // check();
              setReviewInvActiveClass(false);
              setAImapActiveClass(false);
            } else if (
              res.data.result == 0 &&
              data.res.message ==
              "Failed-Invoice date is not Valid(not in the range of the current budget year"
            ) {
              toast.error(
                "Invoice date is not Valid (not in the range of the current budget year )!"
              );
              setLoadingReviewInvoice(false);
              getData(updatedate);
              // check();
              setReviewInvActiveClass(false);
              setAImapActiveClass(false);
            } else {
              toast.error("Data is not saved!");
              setLoadingReviewInvoice(false);
              getData(updatedate);
              // check();
              setReviewInvActiveClass(false);
              setAImapActiveClass(false);
            }
          }
        })
        .catch(() => {
          setLoadingReviewInvoice(false);
          getData(updatedate);
          // check();
          setReviewInvActiveClass(false);
          setAImapActiveClass(false);
          toast.error("Data is not saved!");
        });
    }
  };
  const [showInvoiceNo, setShowInvoiceNo] = useState(false);
  const [showInvoiceDate, setShowInvoiceDate] = useState(false);
  const [showInvDateNo, setShowInvDateNo] = useState(false);
  const InvoiceNoChange = () => {
    setInvoiceNumber(recordStateforMapping.data.invoice_number);
    setShowInvoiceNo(false);
    Finish();
  };
  const InvoiceNumberNotChange = () => {
    recordStateforMapping.data.invoice_number = invoiceNumber;
    setShowInvoiceNo(false);
    Finish();
  };
  const InvoiceDateChange = () => {
    setInvoicedate(recordStateforMapping.data.invoice_date);
    setShowInvoiceDate(false);
    Finish();
  };
  const InvoiceDateNotChange = () => {
    recordStateforMapping.data.invoice_date = invoiceDate;
    setShowInvoiceDate(false);
    Finish();
  };
  const InvoiceDateNoChange = () => {
    setInvoiceNumber(recordStateforMapping.data.invoice_number);
    setInvoicedate(recordStateforMapping.data.invoice_date);
    setShowInvoiceNo(false);
    setShowInvoiceDate(false);
    setShowInvDateNo(false);
    Finish();
  };
  const InvoiceDateNoNotChange = () => {
    recordStateforMapping.data.invoice_date = invoiceDate;
    recordStateforMapping.data.invoice_number = invoiceNumber;
    setShowInvoiceNo(false);
    setShowInvoiceDate(false);
    setShowInvDateNo(false);
    Finish();
  };
  const NextToReviewInvoice = () => {
    setIsChecked(false);
    setLoadingReviewInvoice(false);
    var checkval;
    var Items = recordStateforMapping.data;
    var ItemsDate =
      ReviewData?.ReviewData?.file_status == 2
        ? Items.invoice_date
        : Items.invoice_date.split(" ")[0];
    if (AiMapTableData != undefined && AiMapTableData != "") {
      for (let i = 0; i < AiMapTableData.length; i++) {
        if (ReviewData?.ReviewData?.file_status == 2) {
          if (AiMapTableData[i].Fobecategory == "") {
            checkval = 1;
            i = AiMapTableData.length;
          } else {
            checkval = 0;
          }
        } else {
          if (AiMapTableData[i].Fobecategory == "") {
            checkval = 1;
            i = AiMapTableData.length;
          } else {
            checkval = 0;
          }
        }
      }
      if (checkval == 1) {
        toast.warning("Please Select Category!");
      } else if (
        (checkval == 0 && Items.PurveyorRawName == "") ||
        Items.PurveyorRawName == undefined ||
        Items.PurveyorRawName == 0
      ) {
        toast.warning("Please Fill Purveyor!");
      } else if (
        (checkval == 0 && Items.invoice_number == "") ||
        Items.invoice_number == undefined ||
        Items.invoice_number == 0
      ) {
        toast.warning("Please Fill Invoice No!");
      } else if (
        (checkval == 0 && ItemsDate == "") ||
        ItemsDate == undefined ||
        ItemsDate == 0
      ) {
        toast.warning("Please Fill Invoice Date!");
      } else if (
        checkval == 0 &&
        invoiceNumber != Items.invoice_number &&
        invoiceDate != ItemsDate
      ) {
        setShowInvDateNo(true);
      } else if (
        checkval == 0 &&
        invoiceDate != ItemsDate &&
        invoiceNumber == Items.invoice_number
      ) {
        setShowInvoiceDate(true);
      } else if (
        checkval == 0 &&
        invoiceNumber != Items.invoice_number &&
        invoiceDate == ItemsDate
      ) {
        setShowInvoiceNo(true);
      } else {
        Finish();
      }
    } else {
      toast.warning("No data available!");
    }
  };
  const Finish = () => {
    var Items = recordStateforMapping.data;
    if (
      Items.PurveyorRawName == "" ||
      Items.PurveyorRawName == undefined ||
      Items.PurveyorRawName == 0
    ) {
      toast.warning("Please Fill Purveyor!");
    } else if (
      Items.invoice_number == "" ||
      Items.invoice_number == undefined ||
      Items.invoice_number == 0
    ) {
      toast.warning("Please Fill Invoice No!");
    } else if (
      Items.invoice_date == "" ||
      Items.invoice_date == undefined ||
      Items.invoice_date == 0
    ) {
      toast.warning("Please Fill Invoice Date!");
    } else {
      setReviewInvActiveClass(true);
      setMultiDeleteItems([]);
      setShowDeleteIcon(false);
      var data = {
        enabled: true,
      };

      for (var i = 0; i < AiMapTableData.length; i++) {
        if (Array.isArray(AiMapTableData[i])) {
          AiMapTableData[i].push(data);
        }
      }
      Total();
    }
  };
  // Invoice List
  const closeExistProcessorPopup = () => {
    setexist_Processor(false);
    if (existCheck == "Manual") {
      getData(updatedate);
    } else {
      BackToInvoiceList();
    }
  };
  const SaveStatus = async (record, index) => {
    let d = {
      Rest_id: record.Rest_id,
      upload_id: record.Upload_id,
      filestatus: record.file_status == 1 ? 5 : record.file_status,
    };
    await fobeAxios
      .post("api/Daily/save_invoice_status", d)
      .then((res) => {
        if (res.data.Processor_details != "") {
          setexist_Processor(true);
          setExistText(res.data.Processor_details.processedby);
          setexistCheck("Auto");
        }
        // getData();
        handleDropdown5Change(dropdown5Value);
        check();
        // setTimeout(() => {
        //   setflag(!flag);
        // }, 100);
      })
      .catch(() => {
        setexist_Processor(false);
      });
  };
  const handleChangeStatus = async (event, index, record) => {
    const newValue = event.target.value;
    let indexofRow = tableData.findIndex(
      (x) => x.Upload_id == record.Upload_id
    );

    let temp3 = [...tableData];
    temp3[indexofRow].file_status = newValue;
    setTableData([]);
    setTableData(temp3);
    // setdataForPreandNext(temp3);

    let d = {
      Rest_id: record.Rest_id,
      upload_id: record.Upload_id,
      filestatus: newValue,
    };
    await fobeAxios
      .post("api/Daily/save_invoice_status", d)
      .then((res) => {
        if (
          (newValue == 3 || newValue == 4 || newValue == 6) &&
          res.data.Processor_details == ""
        ) {
          getNotes(record);
        } else {
          check();
          handleDropdown5Change(dropdown5Value);
        }
        if (res.data.Processor_details != "") {
          setexist_Processor(true);
          setExistText(res.data.Processor_details.processedby);
          setexistCheck("Manual");
        }
        setTimeout(() => {
          setflag(!flag);
        }, 500);
      })
      .catch(() => {
        setexist_Processor(false);
      });
  };
  var columns =
    dropdown3Value == "" || dropdown3Value == 2
      ? [
        {
          title: "INV UPLOAD DATE",
          dataIndex: "date",
          width: 150,
          align: "center",
          key: "date",
          sortOrder: sorter.field === "invUploadDate" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              // Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "invUploadDate", order: "ascend" });
              } else {
                setSorter({ field: "invUploadDate", order: "descend" });
              }
            },
          }),
          sorter: (a, b) => {
            return a.date.localeCompare(b.date);
          },
          render: (dateString, record) => {
            const formatDateforInvoiceDate = (dateStr) => {
              const date = new Date(dateStr);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              return `${month}/${day}/${year}`;
            };
            const date = new Date(dateString);
            const formattedTime = date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            const amPm = date.getHours() >= 12 ? "PM" : "AM";
            return (
              <>
                <div>
                  {record.invoice_from === "From_Mail" && (
                    <>
                      <BiEnvelope
                        style={{
                          fontSize: "28px",
                          marginRight: "0.2em",
                          marginTop: "-0.3em",
                          color: "#2196F3",
                        }}
                        className="icon-styles"
                        data-mattooltip="Invoice Email"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {(record.invoice_from === "SPLIT_PDF" ||
                    record.invoice_from === "MERGE_PDF" ||
                    record.invoice_from == "INVOICE_UPLOAD") && (
                      <>
                        <BiUpload
                          style={{
                            fontSize: "28px",
                            marginRight: "0.2em",
                            marginTop: "-0.3em",
                            color: "#2196F3",
                          }}
                          className="icon-styles"
                          data-mattooltip="Invoice Email"
                        />
                        <span>
                          {" "}
                          {formatDateforInvoiceDate(dateString)} {formattedTime}
                        </span>
                      </>
                    )}
                  {record.invoice_from === "SYSCO EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/sys1.png"
                        style={{ width: "1.8em", margin: "-3px 5px" }}
                        data-mattooltip="Sysco EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "PFG EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/PFG.png"
                        style={{
                          width: "2.8em",
                          margin: "0px 6px 3px -10px",
                        }}
                        data-mattooltip="PFG EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "MCLANE EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/McLANE.png"
                        style={{
                          width: "2.8em",
                          margin: "0px 6px 3px -10px",
                        }}
                        data-mattooltip="McLane EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "Benekeith EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/Benekeith.jpg"
                        style={{ width: "2em", margin: "0px 6px 3px -10px" }}
                        data-mattooltip="Benekeith EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "USFOOD" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/Usfoods.png"
                        style={{ width: "3em", margin: "0px 6px 3px -10px" }}
                        data-mattooltip="USFOOD"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                </div>
              </>
            );
          },
        },
        {
          title: "PROCESSED DATE",
          dataIndex: "Completed_date",
          width: 150,
          align: "center",
          key: "Completed_date",
          sorter: (a, b) => {
            if (a.file_status !== 2 && b.file_status !== 2) {
              return 0;
            } else if (a.file_status !== 2) {
              return -1;
            } else if (b.file_status !== 2) {
              return 1;
            } else {
              const dateA = new Date(a.Completed_date);
              const dateB = new Date(b.Completed_date);
              return dateA - dateB;
            }
          },
          sortOrder: sorter.field === "Completed_date" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "Completed_date", order: "ascend" });
              } else {
                setSorter({ field: "Completed_date", order: "descend" });
              }
            },
          }),
          render: (dateString, record) => {
            const formatDateforInvoiceDate = (dateStr) => {
              const date = new Date(dateStr);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              return `${month}/${day}/${year}`;
            };
            const date = new Date(dateString);
            const formattedTime = date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            const amPm = date.getHours() >= 12 ? "PM" : "AM";
            return (
              <>
                {record.file_status == 2 && (
                  <div>
                    {formatDateforInvoiceDate(dateString)} {formattedTime}
                  </div>
                )}
                {record.file_status != 2 && <div>-</div>}
              </>
            );
          },
        },
        {
          title: "RESTAURANT NAME",
          dataIndex: "company",
          key: "company",
          align: "center",
          width: 160,
          sorter: (a, b) => a.company.localeCompare(b.company),
          sortOrder: sorter.field === "restaurantName" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              // Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "restaurantName", order: "ascend" });
              } else {
                setSorter({ field: "restaurantName", order: "descend" });
              }
            },
          }),
          render: (text, record, index) => {
            const handleChange = (index, data) => {
              let selectedOption1 = restlistfordrop.filter(
                (option) =>
                  option.value.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
              setSelectedOption(data);
              let json = {
                label: selectedOption1[0].label,
                value: selectedOption1[0].value,
                upload_id: record.Upload_id,
              };
              setapikey(json);
              handleShow();
            };
            return (
              <>
                {text != "" ? (
                  <div
                    className={
                      record.Admin_Status == "De-Active"
                        ? "reddisable"
                        : record.Upload_id == color_key
                          ? "greenactive"
                          : ""
                    }
                  >
                    {text}
                    <div
                      style={{
                        fontSize: "10px",
                        color: "grey",
                        fontStyle: "italic",
                      }}
                    >
                      {record.Account_Name}
                    </div>
                  </div>
                ) : (
                  <Select
                    showSearch
                    id={index}
                    placeholder="Select Restaurant"
                    value={selectedOption[index]}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                    onChange={(selectedOption) =>
                      handleChange(index, selectedOption)
                    }
                    options={restlistfordrop}
                    isSearchable={true}
                    dropdownRender={(menu) => (
                      <div
                        onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                        onClick={(e) => e.stopPropagation()} // Prevents the dropdown from closing when clicking inside
                        style={{ textAlign: "left" }}
                      >
                        {menu}
                      </div>
                    )}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode
                    }
                  />
                )}
                {record.invoice_from == "From_Mail" && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    {record.invoicefrom_mail}
                  </div>
                )}
                {record.split_pdf && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    Splitted Invoice
                  </div>
                )}
                {record.invoice_from == "MERGE_PDF" && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    Merged Invoice
                  </div>
                )}
              </>
            );
          },
        },
        {
          title: "STATUS",
          dataIndex: "file_status",
          key: "file_status",
          align: "center",
          width: 170,
          sorter: (a, b) => a.file_status - b.file_status,
          sortOrder: sorter.field === "status" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              // Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "status", order: "ascend" });
              } else {
                setSorter({ field: "status", order: "descend" });
              }
            },
          }),
          render: (text, record, index) => {
            // {
            //   console.log("text", record.qa_review);
            // }
            return (
              <div style={{ textAlign: "left", paddingLeft: "2em" }}>
                <select
                  style={{ width: "65%" }}
                  SelectedValue={text}
                  value={text}
                  onChange={(e) => {
                    handleChangeStatus(e, index, record);
                  }}
                  disabled={text == 2 || text == 7 ? true : false}
                  className={
                    text == 2 || text == 7 ? "grey disable greyback" : ""
                  }
                >
                  {items.map((option) => (
                    <option
                      key={option.key}
                      disabled={
                        option.key == "3" &&
                          (record.invoice_from != "PFG EDI" ||
                            record.invoice_from != "SYSCO EDI" ||
                            record.invoice_from != "MCLANE EDI" ||
                            record.invoice_from != "Benekeith EDI" ||
                            record.invoice_from != "USFOOD"
                          )
                          ? false
                          : option.disabled
                      }
                      className={
                        option.disabled ||
                          (option.key == "3" &&
                            (record.invoice_from == "PFG EDI" ||
                              record.invoice_from == "SYSCO EDI" ||
                              record.invoice_from != "MCLANE" ||
                              record.invoice_from != "Benekeith EDI" ||
                              record.invoice_from != "USFOOD"))
                          ? "grey"
                          : ""
                      }
                      value={option.key}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                {text == 2 && record.qa_review != "" && (
                  <span style={{ marginLeft: "0.5em" }}>
                    {record.qa_review == "1" ? (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{
                          color: "#1bb143",
                          // marginLeft: "0.5em",
                          fontSize: "20px",
                          verticalAlign: "-0.25em",
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        style={{
                          color: " #ff4d4d",
                          margin: "-2px auto",
                          fontSize: "20px",
                          verticalAlign: "-0.15em",
                        }}
                      />
                    )}
                  </span>
                )}

                {(text == 3 ||
                  text == 4 ||
                  text == 6 ||
                  (text == 2 && record.qa_review == 2)) && (
                    <span
                      onClick={(e) => {
                        getNotes(record);
                      }}
                    >
                      <Tooltip title="Edit">
                        <BorderColorIcon
                          style={{
                            color: "rgb(0, 173, 255)",
                            cursor: "pointer",
                            fontSize: "1.4em",
                            marginLeft: "0.5em",
                            // marginTop: '-6px'
                            verticalAlign: "-0.25em",
                          }}
                        />
                      </Tooltip>
                    </span>
                  )}
              </div>
            );
          },
        },
        {
          title: "ACTION",
          dataIndex: "ownername",
          key: "ownername",
          width: 180,
          align: "center",
          sorter: (a, b) => a.date.localeCompare(b.date),
          // sortOrder: sorter.field === 'action' && sorter.order,
          // onHeaderCell: () => ({
          //   onClick: () => {
          //     // Handle ascending or descending sorting
          //     if (!sorter.order || sorter.order === 'descend') {
          //       setSorter({ field: 'action', order: 'ascend' });
          //     } else {
          //       setSorter({ field: 'action', order: 'descend' });
          //     }
          //   },
          // }),
          render: (text, record, index) => {
            function Review(record) {
              setAImapActiveClass(true);
              setAutomationReview(false);
              // Code to handle edit functionality here
              var filestatus = record.file_status;
              setFilestatusChange(filestatus);
              setFilestatuschangePreviousandNext(filestatus);
              var data = {
                ReviewData: record,
                index: currentIndex * pageSize + index,
              };
              setMultiDeleteItems([]);
              setShowDeleteIcon(false);
              // getAimappingCategatoryList(data.ReviewData.Rest_id);
              getInvoiceDataUrl(data);
              SaveStatus(record, currentIndex * pageSize + index);
            }
            function ReviewAutomation(record) {
              setAutomationReview(true);
              setAImapActiveClass(true);
              var data = {
                ReviewData: record,
                index: currentIndex * pageSize + index,
              };
              setMultiDeleteItems([]);
              setShowDeleteIcon(false);
              getInvoiceDataUrl(data);
              getAimappingCategatoryList(data.ReviewData.Rest_id);
            }
            function viewinvoice(record) {
              // Code to handle delete functionality here
              window.open(record);
            }

            const getsplitdata = async (val1, val2) => {
              handleShowforshowrevertdata();

              // alert("getsplitdata");
              let data = {
                rest_id: val1,
                upload_id: val2,
              };
              await fobeAxios
                .post("api/Admin/getoperation_forretrieve_old", data)
                .then((resp) => {
                  setshowpdf(resp.data);
                })
                .catch((err) => { });
              //need to call api to get split data and set response data in showpdf
            };
            function split(record) {
              navigate("/split", { state: record });
              // Code to handle delete functionality here
            }
            function Delete(record) {
              handleShow();
              //this.httpClient.get(environment.rootUrl + '/api/Daily/Delete_invoice_details/'+record.Rest_id+'/'+reason);
            }
            return (
              <>
                <div
                  class="col-md-12 col-xs-12 col-sm-12"
                  style={{ paddingLeft: "1.8em", paddingRight: "1.8em" }}
                >
                  <div class="row d-flex">
                    {record.file_status == 2 && (
                      <div class="col-md-3 col-xs-3 col-sm-3">
                        {isScrolledTooltip ? (
                          <FontAwesomeIcon
                            onClick={() => ReviewAutomation(record)}
                            onMouseEnter={() => setisScrolledTooltip(false)}
                            onMouseLeave={() => {
                              setisScrolledTooltip(true);
                            }}
                            icon={faFileLines}
                            className="icon-style icon-review"
                            style={{
                              color: "#183153",
                              fontSize: "22px",
                              padding: "8px 12px 8px 12px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Tooltip title={"Review"}>
                            <FontAwesomeIcon
                              onClick={() => ReviewAutomation(record)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              icon={faFileLines}
                              className="icon-style icon-review"
                              style={{
                                color: "#183153",
                                fontSize: "22px",
                                padding: "8px 12px 8px 12px",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    {((record.file_status != 2 &&
                      record.invoice_from != "From_Mail") ||
                      (record.invoice_from == "From_Mail" &&
                        record.company != "" &&
                        record.file_status != 2)) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <FontAwesomeIcon
                              onClick={() => Review(record)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              icon={faFileLines}
                              className="icon-style icon-review"
                              style={{
                                color: "#183153",
                                fontSize: "22px",
                                padding: "8px 12px 8px 12px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title={"Review"}>
                              <FontAwesomeIcon
                                onClick={() => Review(record)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                icon={faFileLines}
                                className="icon-style icon-review"
                                style={{
                                  color: "#183153",
                                  fontSize: "22px",
                                  padding: "8px 12px 8px 12px",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    {record.split_pdf && record.file_status != 2 && (
                      <div class="col-md-3 col-xs-3 col-sm-3">
                        {isScrolledTooltip ? (
                          <RemoveRedEyeIcon
                            onClick={() =>
                              getsplitdata(record.Rest_id, record.Upload_id)
                            }
                            onMouseEnter={() => setisScrolledTooltip(false)}
                            onMouseLeave={() => {
                              setisScrolledTooltip(true);
                            }}
                            className="icon-style icon-split"
                            style={{
                              color: "#19a73f",
                              fontSize: "41px",
                              padding: "7",
                              marginTop: "0",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Tooltip title="View Source">
                            <RemoveRedEyeIcon
                              onClick={() =>
                                getsplitdata(record.Rest_id, record.Upload_id)
                              }
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-split"
                              style={{
                                color: "#19a73f",
                                fontSize: "41px",
                                padding: "7",
                                marginTop: "0",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    {(record.file_status == 2 ||
                      (record.invoice_from == "From_Mail" &&
                        record.company == "")) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <RemoveRedEyeIcon
                              onClick={() => viewinvoice(record.filename)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-view"
                              style={{
                                color: "#19A73F",
                                fontSize: "41px",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title="View Source">
                              <RemoveRedEyeIcon
                                onClick={() => viewinvoice(record.filename)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                className="icon-style icon-view"
                                style={{
                                  color: "#19A73F",
                                  fontSize: "41px",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    {((record.file_status != 2 &&
                      record.invoice_from != "SYSCO EDI" &&
                      record.invoice_from != "PFG EDI" &&
                      record.invoice_from != "MCLANE EDI" &&
                      record.invoice_from != "Benekeith EDI" &&
                      record.invoice_from != "USFOOD" &&

                      record.company != "") ||
                      (record.invoice_from == "From_Mail" &&
                        record.company != "" &&
                        record.file_status != 2)) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <InsertPageBreakOutlinedIcon
                              onClick={() => split(record)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-split"
                              style={{
                                color: "#FF9800",
                                fontSize: "41px",
                                padding: "7px",
                                marginTop: "0",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title="Split PDF">
                              <InsertPageBreakOutlinedIcon
                                onClick={() => split(record)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                className="icon-style icon-split"
                                style={{
                                  color: "#FF9800",
                                  fontSize: "41px",
                                  padding: "7px",
                                  marginTop: "0",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    <div class="col-md-3 col-xs-3 col-sm-3">
                      {isScrolledTooltip ? (
                        <FontAwesomeIcon
                          onClick={() => {
                            pop("delete", record.Upload_id, record.index);
                          }}
                          onMouseEnter={() => setisScrolledTooltip(false)}
                          onMouseLeave={() => {
                            setisScrolledTooltip(true);
                          }}
                          className="icon-style icon-delete"
                          style={{
                            color: "red",
                            cursor: "pointer",
                            padding: "9px 11px",
                          }}
                          icon={faTrashCan}
                        />
                      ) : (
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              pop("delete", record.Upload_id, record.index);
                            }}
                            onMouseEnter={() => setisScrolledTooltip(false)}
                            onMouseLeave={() => {
                              setisScrolledTooltip(true);
                            }}
                            className="icon-style icon-delete"
                            style={{
                              color: "red",
                              cursor: "pointer",
                              padding: "9px 11px",
                            }}
                            icon={faTrashCan}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          },
        },
      ]
      : [
        {
          title: "",
          dataIndex: "Select",
          key: "Select",
          width: 30,
          align: "center",
          // Render checkbox in each row
          render: (_, record) => {
            return (
              <>
                {record.invoice_from === "INVOICE_UPLOAD" ||
                  record.invoice_from === "From_Mail" ||
                  record.invoice_from === "SPLIT_PDF" ||
                  record.invoice_from === "MERGE_PDF"
                  ? [
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(e, record)}
                      checked={selectedRows.includes(record.Upload_id)}
                    // defaultValue={record.Select}
                    // value={record.Select}
                    />,
                  ]
                  : ["-"]}
              </>
            );
          },
        },
        {
          title: "INV UPLOAD DATE",
          dataIndex: "date",
          width: 100,
          align: "center",
          key: "date",
          sortOrder: sorter.field === "invUploadDate" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              //  Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "invUploadDate", order: "ascend" });
              } else {
                setSorter({ field: "invUploadDate", order: "descend" });
              }
            },
          }),
          sorter: (a, b) => {
            return a.date.localeCompare(b.date);
          },
          render: (dateString, record) => {
            const formatDateforInvoiceDate = (dateStr) => {
              const date = new Date(dateStr);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              return `${month}/${day}/${year}`;
            };
            const date = new Date(dateString);
            const formattedTime = date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            const amPm = date.getHours() >= 12 ? "PM" : "AM";
            return (
              <>
                <div>
                  {record.invoice_from === "From_Mail" && (
                    <>
                      <BiEnvelope
                        style={{
                          fontSize: "28px",
                          marginRight: "0.2em",
                          marginTop: "-0.3em",
                          color: "#2196F3",
                        }}
                        className="icon-styles"
                        data-mattooltip="Invoice Email"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {(record.invoice_from === "SPLIT_PDF" ||
                    record.invoice_from === "MERGE_PDF" ||
                    record.invoice_from == "INVOICE_UPLOAD") && (
                      <>
                        <BiUpload
                          style={{
                            fontSize: "28px",
                            marginRight: "0.2em",
                            marginTop: "-0.3em",
                            color: "#2196F3",
                          }}
                          className="icon-styles"
                          data-mattooltip="Invoice Email"
                        />
                        <span>
                          {" "}
                          {formatDateforInvoiceDate(dateString)} {formattedTime}
                        </span>
                      </>
                    )}
                  {record.invoice_from === "SYSCO EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/sys1.png"
                        style={{ width: "1.8em", margin: "-3px 5px" }}
                        data-mattooltip="Sysco EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "PFG EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/PFG.png"
                        style={{
                          width: "2.8em",
                          margin: "0px 6px 3px -10px",
                        }}
                        data-mattooltip="PFG EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "MCLANE EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/McLANE.png"
                        style={{
                          width: "2.8em",
                          margin: "0px 6px 3px -10px",
                        }}
                        data-mattooltip="McLane EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "Benekeith EDI" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/Benekeith.jpg"
                        style={{ width: "2em", margin: "0px 6px 3px -10px" }}
                        data-mattooltip="Benekeith EDI"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                  {record.invoice_from === "USFOOD" && (
                    <>
                      <img
                        src="https://dev.fobesoft.com/assets/img/Usfoods.png"
                        style={{ width: "3em", margin: "0px 6px 3px -10px" }}
                        data-mattooltip="USFOOD"
                      />
                      <span>
                        {" "}
                        {formatDateforInvoiceDate(dateString)} {formattedTime}
                      </span>
                    </>
                  )}
                </div>
              </>
            );
          },
        },
        {
          title: "RESTAURANT NAME",
          dataIndex: "company",
          key: "company",
          align: "center",
          width: 200,
          sorter: (a, b) => a.company.localeCompare(b.company),
          sortOrder: sorter.field === "restaurantName" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              // Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "restaurantName", order: "ascend" });
              } else {
                setSorter({ field: "restaurantName", order: "descend" });
              }
            },
          }),
          render: (text, record, index) => {
            const handleChange = (index, data, record) => {
              let selectedOption1 = restlistfordrop.filter(
                (option) =>
                  option.value.toString().trim().toLowerCase() ===
                  data.toString().trim().toLowerCase()
              );
              setSelectedOption(data);
              let json = {
                label: selectedOption1[0].label,
                value: selectedOption1[0].value,
                upload_id: record.Upload_id,
              };
              setapikey(json);
              handleShow();
            };
            return (
              <>
                {text != "" ? (
                  <div
                    className={
                      record.Admin_Status == "De-Active" ? "red" : ""
                    }
                  >
                    {text}
                    <div
                      style={{
                        fontSize: "10px",
                        color: "grey",
                        fontStyle: "italic",
                      }}
                    >
                      {record.Account_Name}
                    </div>
                  </div>
                ) : (
                  <Select
                    showSearch
                    id={index}
                    placeholder="Select Restaurant"
                    value={selectedOption[index]}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <div
                        onMouseDown={(e) => e.preventDefault()} // Prevents the dropdown from closing when clicking inside
                        onClick={(e) => e.stopPropagation()} // Prevents the dropdown from closing when clicking inside
                        style={{ textAlign: "left" }}
                      >
                        {menu}
                      </div>
                    )}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode
                    }
                    onChange={(selectedOption) =>
                      handleChange(index, selectedOption, record)
                    }
                    options={restlistfordrop}
                    isSearchable={true}
                  />
                )}
                {record.invoice_from == "From_Mail" && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    {record.invoicefrom_mail}
                  </div>
                )}
                {record.split_pdf && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    Splitted Invoice
                  </div>
                )}
                {record.invoice_from == "MERGE_PDF" && (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      fontStyle: "italic",
                    }}
                  >
                    Merged Invoice
                  </div>
                )}
              </>
            );
          },
        },
        {
          title: "STATUS",
          dataIndex: "file_status",
          key: "file_status",
          align: "center",
          width: 100,
          sorter: (a, b) => a.file_status - b.file_status,
          sortOrder: sorter.field === "status" && sorter.order,
          onHeaderCell: () => ({
            onClick: () => {
              // Handle ascending or descending sorting
              if (!sorter.order || sorter.order === "descend") {
                setSorter({ field: "status", order: "ascend" });
              } else {
                setSorter({ field: "status", order: "descend" });
              }
            },
          }),
          render: (text, record, index) => {
            return (
              <div style={{ textAlign: "left", paddingLeft: "2.2em" }}>
                <select
                  style={{ width: "75%" }}
                  SelectedValue={text}
                  value={text}
                  onChange={(e) => {
                    handleChangeStatus(e, index, record);
                  }}
                  disabled={text == 2 || text == 7 ? true : false}
                  className={
                    text == 2 || text == 7 ? "grey disable greyback" : ""
                  }
                >
                  {items.map((option) => (
                    <option
                      key={option.key}
                      disabled={
                        option.key == "3" &&
                          (record.invoice_from == "PFG EDI" ||
                            record.invoice_from == "SYSCO EDI" ||
                            record.invoice_from == "MCLANE EDI" ||
                            record.invoice_from == "Benekeith EDI" ||
                            record.invoice_from != "USFOOD"
                          )
                          ? true
                          : option.disabled
                      }
                      className={
                        option.disabled ||
                          (option.key == "3" &&
                            (record.invoice_from == "PFG EDI" ||
                              record.invoice_from == "SYSCO EDI" ||
                              record.invoice_from == "MCLANE EDI" ||
                              record.invoice_from == "Benekeith EDI" ||
                              record.invoice_from != "USFOOD"
                            ))
                          ? "grey"
                          : ""
                      }
                      value={option.key}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>

                {(text == 3 || text == 4 || text == 6) && (
                  <span
                    onClick={(e) => {
                      getNotes(record);
                    }}
                  >
                    <Tooltip title="Edit">
                      <BorderColorIcon
                        style={{
                          color: "rgb(0, 173, 255)",
                          cursor: "pointer",
                          fontSize: "1.4em",
                          marginLeft: "0.5em",
                          verticalAlign: "-0.21em",
                        }}
                      />
                    </Tooltip>
                  </span>
                )}
              </div>
            );
          },
        },
        {
          title: "ACTION",
          dataIndex: "ownername",
          key: "ownername",
          width: 130,
          align: "center",
          sorter: (a, b) => a.date.localeCompare(b.date),
          // sortOrder: sorter.field === 'action' && sorter.order,
          // onHeaderCell: () => ({
          //   onClick: () => {
          //     // Handle ascending or descending sorting
          //     if (!sorter.order || sorter.order === 'descend') {
          //       setSorter({ field: 'action', order: 'ascend' });
          //     } else {
          //       setSorter({ field: 'action', order: 'descend' });
          //     }
          //   },
          // }),
          render: (text, record, index) => {
            function Review(record) {
              setAImapActiveClass(true);
              setAutomationReview(false);
              // Code to handle edit functionality here
              var filestatus = record.file_status;
              setFilestatusChange(filestatus);
              setFilestatuschangePreviousandNext(filestatus);
              var data = {
                ReviewData: record,
                index: currentIndex * pageSize + index,
              };
              setMultiDeleteItems([]);
              setShowDeleteIcon(false);
              getInvoiceDataUrl(data);
              // getAimappingCategatoryList(data.ReviewData.Rest_id);
              SaveStatus(record, currentIndex * pageSize + index);
            }
            function viewinvoice(record) {
              // Code to handle delete functionality here
              window.open(record);
            }

            const getsplitdata = async (val1, val2) => {
              handleShowforshowrevertdata();

              // alert("getsplitdata");
              let data = {
                rest_id: val1,
                upload_id: val2,
              };
              await fobeAxios
                .post("api/Admin/getoperation_forretrieve_old", data)
                .then((resp) => {
                  setshowpdf(resp.data);
                })
                .catch((err) => { });
              //need to call api to get split data and set response data in showpdf
            };
            function split(record) {
              navigate("/split", { state: record });
              // Code to handle delete functionality here
            }
            function Delete(record) {
              handleShow();
              //this.httpClient.get(environment.rootUrl + '/api/Daily/Delete_invoice_details/'+record.Rest_id+'/'+reason);
            }
            return (
              <>
                <div
                  class="col-md-12 col-xs-12 col-sm-12"
                  style={{ paddingLeft: "1.8em", paddingRight: "1.8em" }}
                >
                  <div class="row d-flex">
                    {((record.file_status != 2 &&
                      record.invoice_from != "From_Mail") ||
                      (record.invoice_from == "From_Mail" &&
                        record.company != "" &&
                        record.file_status != 2)) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <FontAwesomeIcon
                              onClick={() => Review(record)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              icon={faFileLines}
                              className="icon-style icon-review"
                              style={{
                                color: "#183153",
                                fontSize: "22px",
                                padding: "8px 12px 8px 12px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title={"Review"}>
                              <FontAwesomeIcon
                                onClick={() => Review(record)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                icon={faFileLines}
                                className="icon-style icon-review"
                                style={{
                                  color: "#183153",
                                  fontSize: "22px",
                                  padding: "8px 12px 8px 12px",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}

                    {record.split_pdf && record.file_status != 2 && (
                      <div class="col-md-3 col-xs-3 col-sm-3">
                        {isScrolledTooltip ? (
                          <RemoveRedEyeIcon
                            onClick={() =>
                              getsplitdata(record.Rest_id, record.Upload_id)
                            }
                            onMouseEnter={() => setisScrolledTooltip(false)}
                            onMouseLeave={() => {
                              setisScrolledTooltip(true);
                            }}
                            className="icon-style icon-split"
                            style={{
                              color: "#19a73f",
                              fontSize: "41px",
                              padding: "7",
                              marginTop: "0",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Tooltip title="View Source">
                            <RemoveRedEyeIcon
                              onClick={() =>
                                getsplitdata(record.Rest_id, record.Upload_id)
                              }
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-split"
                              style={{
                                color: "#19a73f",
                                fontSize: "41px",
                                padding: "7",
                                marginTop: "0",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    {(record.file_status == 2 ||
                      (record.invoice_from == "From_Mail" &&
                        record.company == "")) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <RemoveRedEyeIcon
                              onClick={() => viewinvoice(record.filename)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-view"
                              style={{
                                color: "#19A73F",
                                fontSize: "41px",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title="View Source">
                              <RemoveRedEyeIcon
                                onClick={() => viewinvoice(record.filename)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                className="icon-style icon-view"
                                style={{
                                  color: "#19A73F",
                                  fontSize: "41px",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    {((record.file_status != 2 &&
                      record.invoice_from != "SYSCO EDI" &&
                      record.invoice_from != "PFG EDI" &&
                      record.invoice_from != "MCLANE EDI" &&
                      record.invoice_from != "Benekeith EDI" &&
                      record.invoice_from != "USFOOD" &&
                      record.company != "") ||
                      (record.invoice_from == "From_Mail" &&
                        record.company != "" &&
                        record.file_status != 2)) && (
                        <div class="col-md-3 col-xs-3 col-sm-3">
                          {isScrolledTooltip ? (
                            <InsertPageBreakOutlinedIcon
                              onClick={() => split(record)}
                              onMouseEnter={() => setisScrolledTooltip(false)}
                              onMouseLeave={() => {
                                setisScrolledTooltip(true);
                              }}
                              className="icon-style icon-split"
                              style={{
                                color: "#FF9800",
                                fontSize: "41px",
                                padding: "7px",
                                marginTop: "0",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Tooltip title="Split PDF">
                              <InsertPageBreakOutlinedIcon
                                onClick={() => split(record)}
                                onMouseEnter={() => setisScrolledTooltip(false)}
                                onMouseLeave={() => {
                                  setisScrolledTooltip(true);
                                }}
                                className="icon-style icon-split"
                                style={{
                                  color: "#FF9800",
                                  fontSize: "41px",
                                  padding: "7px",
                                  marginTop: "0",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    <div class="col-md-3 col-xs-3 col-sm-3">
                      {isScrolledTooltip ? (
                        <FontAwesomeIcon
                          onClick={() =>
                            pop("delete", record.Upload_id, record.index)
                          }
                          onMouseEnter={() => setisScrolledTooltip(false)}
                          onMouseLeave={() => {
                            setisScrolledTooltip(true);
                          }}
                          className="icon-style icon-delete"
                          style={{
                            color: "red",
                            cursor: "pointer",
                            padding: "9px 11px",
                          }}
                          icon={faTrashCan}
                        />
                      ) : (
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() =>
                              pop("delete", record.Upload_id, record.index)
                            }
                            onMouseEnter={() => setisScrolledTooltip(false)}
                            onMouseLeave={() => {
                              setisScrolledTooltip(true);
                            }}
                            className="icon-style icon-delete"
                            style={{
                              color: "red",
                              cursor: "pointer",
                              padding: "9px 11px",
                            }}
                            icon={faTrashCan}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          },
        },
      ];

  useEffect(() => {
    if (MultiDeleteItems.length > 1) {
      setShowDeleteIcon(true);
    } else {
      setShowDeleteIcon(false);
    }
  }, [MultiDeleteItems]);
  useEffect(() => {
    // const { current, pageSize } = pagination;
    document.documentElement.style.backgroundColor = "#fff";
    const filteredData = tableData.filter((item) => {
      let match = true;

      Object.keys(filters).forEach((key) => {
        const filterValue = filters[key][0];

        if (filterValue && item[key] !== filterValue) {
          match = false;
        }
      });

      return match;
    });

    const sortedData = sorter.field
      ? filteredData.sort((a, b) => {
        if (sorter.order === "descend") {
          return b[sorter.field] - a[sorter.field];
        } else {
          return a[sorter.field] - b[sorter.field];
        }
      })
      : filteredData;

    // const start = (current - 1) * pageSize;
    // const end = start + pageSize;
    // const paginatedData = sortedData.slice(start, end);

    // setTableData(paginatedData);
  }, [filters, sorter]);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChangeNextImage = () => {
    if (existImageFile?.length - 1 > DuplicateInvImgIndex) {
      setDuplicateInvImgIndex(DuplicateInvImgIndex + 1);
      if (DuplicateInvImgIndex >= 1) {
        if (DuplicateInvImgIndex == existImageFile?.length - 2) {
          setpageCountTwo(existImageFile?.length - 1);
        } else {
          setpageCountTwo(pageCountTwo + 1);
        }
      } else {
        setpageCountTwo(2);
      }
    }
  };
  const handleChangePreviousImage = () => {
    if (0 < DuplicateInvImgIndex) {
      setDuplicateInvImgIndex(DuplicateInvImgIndex - 1);
      if (DuplicateInvImgIndex >= 2) {
        if (DuplicateInvImgIndex == existImageFile?.length - 1) {
          setpageCountTwo(existImageFile?.length - 1);
        } else {
          setpageCountTwo(pageCountTwo - 1);
        }
      } else {
        setpageCountTwo(2);
      }
    }
  };
  const invoicedetails = [
    <>
      <div
        class="container bg-white "
        style={{ bottom: selectedRows?.length > 1 && "20px" }}
      >
        <div class="container wiz-nu">
          <ul
            class="nav nav-tabscustomize nav-fill wizard"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item active">
              <a
                href="#home"
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <span className="head">
                  <FilePdfOutlined />
                </span>{" "}
                Invoice List
              </a>
            </li>
            <li className={AImapActiveClass ? "nav-item active" : "nav-item"}>
              <a class="nav-link" aria-controls="profile" aria-selected="false">
                <span className="head">
                  <FileTextOutlined />
                </span>{" "}
                AI Mapping{" "}
              </a>
            </li>
            <li
              className={ReviewInvActiveClass ? "nav-item active" : "nav-item"}
            >
              <a class="nav-link" aria-controls="contact" aria-selected="false">
                <FontAwesomeIcon icon={faPaste} style={{ fontSize: "1em" }} />{" "}
                Review Invoice
              </a>
            </li>
          </ul>
        </div>
        <div class="row cont-up tab-content" id="myTabContent">
          <div
            class="tab-pane fade active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row mt-0">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {!AImapActiveClass && !ReviewInvActiveClass && (
                  <FilterSection
                    // lo={loading}
                    // handleDropdown1Change={handleDropdown1Change}
                    // handleDropdown2Change={handleDropdown2Change}
                    // handleDropdown3Change={handleDropdown3Change}
                    // handleDropdown4Change={handleDropdown4Change}
                    handleDropdown5Change={handleDropdown5Change}
                    // dropdown1Value={dropdown1Value}
                    // dropdown2Value={dropdown2Value}
                    // dropdown3Value={dropdown3Value}
                    // dropdown4Value={dropdown4Value}
                    dropdown5Value={dropdown5Value}
                    updatedate={updatedate}
                    currentDate={currentDate}
                    handleDateChange={handleDateChange}
                    getData={getdata1}
                    setLoading={setLoading}
                    loading={loading}
                  />
                )}
              </div>
              {!AImapActiveClass && !ReviewInvActiveClass ? (
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                  <div class="row mb-3">
                    <div
                      className={
                        dropdown3Value == 2
                          ? "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                          : "col-xs-12 col-sm-12 col-md-5 col-lg-4"
                      }
                    >
                      <div class="row">
                        <div
                          className={
                            dropdown3Value == 2
                              ? "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                              : "col-xs-12 col-sm-6 col-md-6 col-lg-6"
                          }
                        >
                          <div class="col-xs-12">
                            <label
                              style={{ fontSize: "14px", color: "#666666" }}
                            >
                              Invoice Type :
                            </label>
                          </div>
                          <div class="col-xs-9 mt-2">
                            <select
                              className="form-control"
                              value={dropdown1Value}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                handleDropdown1Change(e.target.value)
                              }
                            >
                              <option value="">All</option>
                              <option value="INVOICE_UPLOAD">
                                Invoice Upload
                              </option>
                              <option value="SYSCO EDI">Sysco EDI</option>
                              <option value="PFG EDI">PFG EDI</option>
                              <option value="MCLANE EDI">McLane EDI</option>
                              <option value="Benekeith EDI">Ben E. Keith EDI</option>
                              <option value="USFOOD">US Foods EDI</option>
                              <option value="From_Mail">Invoice Email</option>
                              <option value="SPLIT_PDF">Split Invoice</option>
                              <option value="MERGE_PDF">Merge Invoice</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className={dropdown3Value == 2  ? "col-xs-12 col-sm-3 col-md-3 col-lg-3" : "col-xs-12 col-sm-4 col-md-4 col-lg-4"}>
                <div class="col-xs-12 ">
                  <label style={{ fontSize: "14px", color: "#666666" }}>Budget type :</label>
                </div>
                <div class="col-xs-9 mt-2">
                  <select
                    className="form-control"
                    value={dropdown2Value}
                    style={{ width: '100%' }}
                    onChange={handleDropdown2Change}
                  >
                    <option value="">Select</option>
                    <option value="2">12 Months</option>
                    <option value="1">13 Period</option>
                  </select>
                </div>
              </div> */}
                        <div
                          className={
                            dropdown3Value == 2
                              ? "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                              : "col-xs-12 col-sm-6 col-md-6 col-lg-6"
                          }
                        >
                          <div class="col-xs-12 ">
                            <label
                              style={{ fontSize: "14px", color: "#666666" }}
                            >
                              Status :
                            </label>
                          </div>
                          <div class="col-xs-9 mt-2">
                            <select
                              className="form-control"
                              value={dropdown3Value}
                              style={{ width: "95%" }}
                              onChange={(e) =>
                                handleDropdown3Change(e.target.value)
                              }
                            >
                              <option value="">All</option>
                              <option value="1" selected>
                                Yet to Start
                              </option>
                              <option value="5">Processing</option>
                              <option value="2">Completed</option>
                              <option value="3">Re-scan</option>
                              <option value="4">Flagged</option>
                              <option value="6">Re-Scanned</option>
                              <option value="7">Duplicate</option>
                            </select>
                          </div>
                        </div>
                        {dropdown3Value == 2 && (
                          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div class="col-xs-12 ">
                              <label
                                style={{ fontSize: "14px", color: "#666666" }}
                              >
                                Status :
                              </label>
                            </div>
                            <div class="col-xs-9 mt-2">
                              <select
                                className="form-control"
                                value={dropdown4Value}
                                style={{ width: "95%" }}
                                onChange={(e) =>
                                  handleDropdown4Change(e.target.value)
                                }
                              >
                                <option value="">All</option>
                                <option value="0">Manual </option>
                                <option value="1">Automatic</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        dropdown3Value == 2
                          ? "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                          : "col-xs-12 col-sm-12 col-md-7 col-lg-8 mb-3"
                      }
                    >
                      <div
                        class="row"
                        style={{
                          float: "right",
                          textAlign: "right",
                          marginTop: "2em",
                        }}
                      >
                        <Search
                          placeholder="Search"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchText || ""}
                          style={{
                            width: "100%",
                            float: "right",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="datatableone-container">
                    <DataTable
                      scrollenable={setisScrolledTooltip}
                      scrolleb={isScrolledTooltip}
                      columns={columns}
                      tableData={tableData}
                      handleTableChange={handleTableChange}
                      sorter={sorter}
                      filters={filters}
                      loading={loading}
                      currentIndex={currentIndex}
                      pageSize={pageSize}
                      totalPages={totalPages}
                    />
                  </div>
                </div>
              ) : AImapActiveClass && !ReviewInvActiveClass ? (
                <>
                  <Spin
                    tip="Loading Please wait..."
                    spinning={Loadingaimapfull}
                  >
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 metTotalb-2">
                      <MappingTopBar
                        total={total}
                        qa_review={qa_review}
                        file_status={file_status}
                        setFile_status={setFile_status}
                        setQa_review={setQa_review}
                        changingStatus={setFilestatuschangePreviousandNext}
                        setTotal={setTotal}
                        flter={check}
                        nextAndPrevious={nextAndPrevious}
                        showDeleteIcon={showDeleteIcon}
                        nDisabled={nextDisabled}
                        setnDisabled={setNextDisabled}
                        Multideleteitem={Multideleteitem}
                        tdata={tableData}
                        fonlydisabled={foronlydisabled}
                        pDisabled={previousDisabled}
                        setpDisabled={setPreviousDisabled}
                        Deletepopup={pop}
                        reFreshFUnction={RescanFunction}
                        record={ReviewData}
                        invoiceData={recordStateforMapping}
                        dfortotal={AiMapTableData}
                        setLoading={setLoading}
                        loading={loading}
                        tadata={tableData}
                        setNoterecord={setNoterecord}
                        setNotes={setNotes}
                        setNotesPopup={setNotesPopup}
                        setflag={setflag}
                        check={check}
                        setLoadingaimap={setLoadingaimap}
                        getData={getData}
                        handleDropdown5Change={handleDropdown5Change}
                        dropdown5Value={dropdown5Value}
                        flag={flag}
                      />
                      {/* <Search
                        placeholder="Search"
                        onChange={AiMappinghandleSearch}
                        style={{ width: 300, marginBottom: 16, float: "right" }}
                      /> */}
                      <div className="datatable-container">
                        <DataTable1
                          columns={AiMapColumn}
                          st={setIsTableScrolled}
                          st1={isTableScrolled}
                          tableData1={AiMapTableData}
                          handleTableChange={AiMapTableHandleChange}
                          sorter={AiMapTableDataSoter}
                          filters={AiMapTableDataFilter}
                          loading={Loadingaimap}
                          tdata={tableData}
                        />
                      </div>
                      <div className="row mt-3 ">
                        <div
                          className="col-md-12"
                          style={{ textAlign: "right" }}
                        >
                          {ReviewFullData.file_status != 2 ? (
                            <Bbutton
                              variant="contained"
                              color="success"
                              className="mb-4"
                              style={{
                                background: "#4caf50",
                                border: "none",
                                fontSize: "1em",
                              }}
                              onClick={() => addNewLineItem()}
                            >
                              <FontAwesomeIcon
                                style={{
                                  marginRight: "0.5em",
                                  fontSize: "1.1em",
                                }}
                                icon={faCirclePlus}
                                disabled={AiMapTableData == undefined}
                              />
                              NEW LINE ITEM
                            </Bbutton>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div className="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <Bbutton
                            className="backtoinvoicebutton"
                            variant="secondary"
                            onClick={BackToInvoiceList}
                            style={{ fontSize: "1em" }}
                            boxShadow="large"
                          >
                            <i
                              class="fa-solid fa-arrow-left"
                              style={{ fontWeight: "bold" }}
                            ></i>
                            &nbsp; BACK TO INVOICE LIST
                          </Bbutton>
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div
                            className="twode"
                            style={{
                              display: "flex",
                              gap: "15px",
                              float: "right",
                            }}
                          >
                            {ReviewFullData.file_status == 2 && (
                              <>
                                <Bbutton
                                  variant="contained"
                                  onClick={() => AcceptReject(1)}
                                  style={{
                                    fontSize: "1em",
                                    float: "right",
                                    background: "#1bb143",
                                  }}
                                  boxShadow="large"
                                >
                                  Accept&nbsp;
                                  <FontAwesomeIcon icon={faCheck} />
                                </Bbutton>
                                <Bbutton
                                  variant="contained"
                                  onClick={() => AcceptReject(2)}
                                  style={{
                                    fontSize: "1em",
                                    float: "right",
                                    background: "#ff4d4d",
                                  }}
                                  boxShadow="large"
                                >
                                  Reject&nbsp;
                                  <FontAwesomeIcon icon={faTimes} />
                                </Bbutton>
                              </>
                            )}
                            {file_status != 7 && (
                              <Bbutton
                                variant="contained"
                                color="primary"
                                style={{ fontSize: "1em", float: "right" }}
                                onClick={NextToReviewInvoice}
                                boxShadow="large"
                              >
                                NEXT&nbsp;
                                <i class="fa-solid fa-arrow-right"></i>
                              </Bbutton>
                            )}
                          </div>
                        </div>

                        {/* {ReviewFullData.file_status == 2 ? (
                            
                          ) : (
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                          )}
                          {file_status != 7  && (
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            
                          </div>
                          )} */}
                      </div>
                    </div>
                  </Spin>
                </>
              ) : (
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                  <MappingTopBarReview
                    qa_review={qa_review}
                    setQa_review={setQa_review}
                    total={total}
                    setTotal={setTotal}
                    nextAndPrevious={nextAndPrevious}
                    showDeleteIcon={showDeleteIcon}
                    nDisabled={nextDisabled}
                    Multideleteitem={Multideleteitem}
                    pDisabled={previousDisabled}
                    Deletepopup={pop}
                    tdata={tableData}
                    fonlydisabled={foronlydisabled}
                    reFreshFUnction={RescanFunction}
                    record={ReviewData}
                    invoiceData={recordStateforMapping}
                    fortotal={AiMapTableData}
                    setLoading={setLoading}
                    loading={loading}
                  />
                  {/* <Search
                      placeholder="Search"
                      onChange={AiMappinghandleSearch}
                      style={{ width: 300, marginBottom: 16, float: "right" }}
                    /> */}
                  <div className="datatabled-container">
                    <DataTable2
                      columns={ReviewColumn}
                      LoadingReviewInvoice={LoadingReviewInvoice}
                      tableData2={AiMapTableData}
                      handleTableChange={AiMapTableHandleChange}
                      sorter={AiMapTableDataSoter}
                      filters={AiMapTableDataFilter}
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                    <div className="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                        <Bbutton
                          className="backtoinvoicebutton"
                          boxShadow="large"
                          style={{ fontSize: "0.8em" }}
                          onClick={() => {
                            setAImapActiveClass(true);
                            setReviewInvActiveClass(false);
                            setflag(!flag);
                            var data = {
                              enabled: false,
                            };
                            for (var i = 0; i < AiMapTableData.length; i++) {
                              if (Array.isArray(AiMapTableData[i])) {
                                AiMapTableData[i].push(data);
                              }
                            }
                          }}
                        >
                          <i
                            class="fa-solid fa-arrow-left"
                            style={{ fontWeight: "bold" }}
                          ></i>
                          &nbsp; BACK TO AI MAPPING
                        </Bbutton>
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                        <Bbutton
                          variant="contained"
                          color="primary"
                          style={{ float: "right" }}
                          onClick={FinishToInvoice}
                          boxShadow="large"
                        >
                          Finish{" "}
                          <FontAwesomeIcon
                            icon={faThumbsUp}
                            style={{
                              marginLeft: "0.5em",
                              marginTop: "-0.4em",
                            }}
                          />
                        </Bbutton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedRows?.length > 1 && (
        <div className="footer">
          <div className="container">
            <div className="insidefooter">
              <button
                className="merge-button"
                onClick={() => {
                  handlemergeShow(selectedRows);
                }}
              >
                <BiMerge style={{ color: "#24d153", fontSize: "24px" }} /> Merge
                Invoices
              </button>
            </div>
          </div>
        </div>
      )}
    </>,
  ];
  return (
    <>
      <ToastContainer autoClose={1500} />
      <div style={{ background: "#fff" }}>
        <Header
          handleMobileMenuClose={handleMobileMenuClose}
          anchorEl={anchorEl}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleMenuClose={handleMenuClose}
          loginUserName={loginUserName}
          handleMenuCloseout={handleMenuCloseout}
          handleMobileMenuOpen={handleMobileMenuOpen}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          element={invoicedetails}
        />

        <Modal
          show={IndicationOfDuplicateInvoice}
          // onHide={() => {
          //   setIndicationOfDuplicateInvoice(false);
          // }}
          backdrop="static"
          keyboard={false}
          top
          dialogClassName="modal-lg modal-xl custom-modal"
        >
          <Modal.Body>
            <span
              className="close"
              onClick={() => {
                setIndicationOfDuplicateInvoice(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
            <div
              className="layout"
              style={{
                display: "flex",
                gap: "24px",
                border: "1px solid #DDDDDD",
                padding: "5px 25px 5px 25px",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  borderRight: "1px solid #DDDDDD",
                  paddingRight: "43px",
                }}
              >
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #DDDDDD" }}
                >
                  <Modal.Title
                    style={{
                      marginBottom: "5px",
                      backgroundColor: "#FF6363",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "4px",
                      padding: "11px 0px 3px 25px",
                      display: "flex",
                    }}
                  >
                    <div className="col">
                      <h5
                        className="proin"
                        style={{ fontSize: "19px", color: "#fff" }}
                      >
                        Duplicate Invoice
                      </h5>
                    </div>
                    <div className="col">
                      <i class="fa-solid fa-circle-xmark iconfordup"></i>
                    </div>
                  </Modal.Title>
                </div>
                <div className="row">
                  <div
                    className="fortablepro"
                    style={{
                      border: "1px solid #7b7b7b",
                      marginTop: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <table
                      class="table duplitab"
                      style={{ fontSize: "14px", marginBottom: "0" }}
                    >
                      <tbody>
                        <tr>
                          <td>Restaurant</td>
                          <td>{ReviewFullData.company}</td>
                        </tr>
                        <tr>
                          <td>Invoice No</td>
                          <td>{invoiceNumber}</td>
                        </tr>
                        <tr>
                          <td>Invoice date</td>
                          <td>{invoiceDate}</td>
                        </tr>
                        <tr>
                          <td>Purveyor</td>
                          <td>{Purveyor}</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>${total ? total?.toFixed(2) : 0.0}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="image-container">
                    <a
                      href={ReviewFullData.filename}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="dupliimg"
                        src={
                          duplicateImageFile?.length > 0
                            ? duplicateImageFile[DuplicateInvImgIndex]
                            : ""
                        }
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "290px",
                          padding: "0px",
                          marginTop: "7px",
                        }}
                      />
                      <div className="overlay">
                        <i className="fa fa-external-link-alt"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div class="paginationo">
                    <div class="page-numberso">
                      {duplicateImageFile?.length > 4 ? (
                        <>
                          <button
                            class={
                              DuplicateInvImgIndex + 1 === pageCountOne
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {pageCountOne}
                          </button>
                          {DuplicateInvImgIndex > 4 && <span>...</span>}
                          <button
                            class={
                              DuplicateInvImgIndex + 1 === pageCountTwo
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {pageCountTwo}
                          </button>
                          {DuplicateInvImgIndex < 5 && <span>...</span>}
                          <button
                            class={
                              DuplicateInvImgIndex + 1 ===
                                existImageFile?.length
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {existImageFile?.length}
                          </button>
                        </>
                      ) : (
                        <>
                          {duplicateImageFile?.map((data, idx) => (
                            <button
                              class={
                                DuplicateInvImgIndex === idx
                                  ? "btn-pageo btn-selectedo"
                                  : "btn-pageo"
                              }
                            >
                              {idx + 1}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rounded-icons"
                style={{
                  flex: "0 0 auto",
                  marginLeft: "-44px",
                  marginTop: "200px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #666666",
                    background: "#939393",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <i
                    className="fa fa-angle-left"
                    style={{
                      color: "#FFF",
                      fontSize: "18px",
                      marginRight: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangePreviousImage();
                    }}
                  ></i>
                  <i
                    className="fa fa-angle-right"
                    style={{
                      color: "#FFF",
                      fontSize: "18px",
                      marginLeft: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeNextImage();
                    }}
                  ></i>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "0px" }}>
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #DDDDDD" }}
                >
                  <Modal.Title
                    style={{
                      marginBottom: "5px",
                      backgroundColor: "#53C120",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "4px",
                      padding: "11px 0px 3px 25px",
                      display: "flex",
                    }}
                  >
                    <div className="col">
                      <h5
                        className="proin"
                        style={{ fontSize: "19px", color: "#fff" }}
                      >
                        Processed Invoice
                      </h5>
                    </div>
                    <div className="col">
                      <i class="fas fa-check-circle iconfordup"></i>
                    </div>
                  </Modal.Title>
                </div>
                <div className="row">
                  <div
                    className="fortablepro"
                    style={{
                      border: "1px solid #7b7b7b",
                      marginTop: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <table
                      class="table duplitab"
                      style={{ fontSize: "14px", marginBottom: "0" }}
                    >
                      <tbody>
                        <tr>
                          <td>Restaurant</td>
                          <td>
                            {existSourceFile?.length > 0
                              ? existSourceFile[0].restaurant_name
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Invoice No</td>
                          <td>
                            {existSourceFile?.length > 0
                              ? existSourceFile[0].Existing_invoice_number
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Invoice date</td>
                          <td>
                            {existSourceFile?.length > 0
                              ? moment(
                                new Date(
                                  existSourceFile[0].Existing_invoice_date
                                )
                              ).format("YYYY-MM-DD")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Purveyor</td>
                          <td>
                            {existSourceFile?.length > 0
                              ? existSourceFile[0].Existing_purveyor
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            $
                            {existSourceFile?.length > 0
                              ? existSourceFile[0].Existing_amount.toFixed(2)
                              : 0.0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="image-container">
                    <a
                      href={
                        existSourceFile?.length > 0
                          ? existSourceFile[0].existing_filename
                          : ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="dupliimg"
                        src={
                          existImageFile?.length > 0
                            ? existImageFile[DuplicateInvImgIndex]
                            : ""
                        }
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "290px",
                          padding: "0px",
                          marginTop: "7px",
                        }}
                      />
                      <div className="overlay">
                        <i className="fa fa-external-link-alt"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div class="paginationo">
                    <div class="page-numberso">
                      {duplicateImageFile?.length > 4 ? (
                        <>
                          <button
                            class={
                              DuplicateInvImgIndex + 1 === pageCountOne
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {pageCountOne}
                          </button>
                          {DuplicateInvImgIndex > 4 && <span>...</span>}
                          <button
                            class={
                              DuplicateInvImgIndex + 1 === pageCountTwo
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {pageCountTwo}
                          </button>
                          {DuplicateInvImgIndex < 5 && <span>...</span>}
                          <button
                            class={
                              DuplicateInvImgIndex + 1 ===
                                existImageFile?.length
                                ? "btn-pageo btn-selectedo"
                                : "btn-pageo"
                            }
                          >
                            {existImageFile?.length}
                          </button>
                        </>
                      ) : (
                        <>
                          {duplicateImageFile?.map((data, idx) => (
                            <button
                              class={
                                DuplicateInvImgIndex === idx
                                  ? "btn-pageo btn-selectedo"
                                  : "btn-pageo"
                              }
                            >
                              {idx + 1}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", marginTop: "-15px" }}>
            <button
              style={{ backgroundColor: 'grey' }}
              className="MarkAsDuplicateButton"
              onClick={() => {
                setIndicationOfDuplicateInvoice(false);
              }}
            >
              Not a Duplicate
            </button>
            <button

              className="MarkAsDuplicateButton"
              onClick={handlechangeStatus}
            >
              Duplicate
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          style={{ marginTop: "6em" }}
          show={IndicationOfSyscoDuplicateInvoice}
          backdrop="static"
          keyboard={false}
          top
        >
          <Modal.Header
            style={{
              backgroundColor: "rgb(25, 167, 63)",
              height: "45px",
              borderRadius: "3px",
            }}
          >
            <span
              className="close"
              onClick={() => {
                setIndicationOfSyscoDuplicateInvoice(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Duplicate Invoice Alert
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>This apperas to be duplicate invoice.</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                background: "rgb(76, 175, 80)",
                border: "none",
                borderRadius: "3px",
              }}
              onClick={() => handlechangeStatus()}
            >
              Mark As Duplicate
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Restaurant Mapping
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure this is the correct restaurant for this email?
          </Modal.Body>
          <Modal.Footer>
            <Bbutton onClick={handleClose} style={{ marginRight: "10px" }}>
              Close
            </Bbutton>
            <Bbutton variant="contained" onClick={mapcatname}>
              Save Changes
            </Bbutton>
          </Modal.Footer>
        </Modal>

        {/* Exist Processor */}

        <Modal
          show={exist_Processor}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Already under processing
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            {ExistText == "" ? "" : ExistText} has already started processing
            this invoice. Please move on to the next.
          </Modal.Body>
          <Modal.Footer>
            <Bbutton
              onClick={closeExistProcessorPopup}
              style={{ marginRight: "10px" }}
            >
              Close
            </Bbutton>
          </Modal.Footer>
        </Modal>

        {/* ------------------- */}

        <Modal
          style={{ marginTop: "8em" }}
          show={showrevertdata}
          onHide={handleCloseforshowrevertdata}
          top
          className="eyemodal"
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Source Invoice
              </h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setshowrevertdata(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body>
            {/* Are you sure this is the correct restaurant for this email?{" "} */}
            <table class="my-table">
              <thead>
                <tr>
                  <th scope="col">
                    <div class="backbrown">Invoice upload Date</div>
                  </th>
                  <th scope="col">
                    <div class="backbrown">RESTAURANT NAME</div>
                  </th>
                  <th scope="col">
                    <div class="backbrown">INVOICE FROM</div>
                  </th>
                  <th scope="col">
                    <div class="backbrown">ACTION</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="bodytg">{showpdf.inserted_date}</div>
                  </td>

                  <td>
                    <div className="bodytg">{showpdf.rest_name}</div>
                  </td>

                  <td>
                    <div className="bodytg">
                      <span
                        style={{
                          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: "3px",
                          fontSize: "20px",
                          padding: "10px 3px 8px 9px",
                        }}
                      >
                        <BiUpload
                          style={{
                            fontSize: "28px",
                            marginRight: "0.2em",
                            marginTop: "-0.3em",
                            color: "#2196F3",
                          }}
                          className="icon-styles"
                          data-mattooltip="Invoice Email"
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="bodytg">
                      {" "}
                      <img
                        onClick={() => viewinvoicee(showpdf.source_file)}
                        data-mattooltip="View Invoice"
                        style={{
                          width: "32px",
                          height: "32px",
                          margin: "auto",
                          cursor: "pointer",
                          touchAction: "none",
                          userSelect: "none",
                        }}
                        src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/pdf.png"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span className="revertback">
                        <svg
                          style={{
                            color: "rgb(255, 152, 0)",
                            fontSize: "22px",
                            touchAction: "none",
                            userSelect: "none",
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={() => pop("revertsplit", "", "")}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-clockwise"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                          />
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        <Modal
          show={showConformPopforRevertdata}
          onHide={handleCloseshowConformPopforRevertdata}
          top
        >
          <Modal.Header
            style={{
              height: "45px",
              borderRadius: "3px",
              background: "#ec981b",
            }}
          >
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Revert Invoice
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontSize: "14px", textAlign: "center", color: "#666" }}>
              Are you sure you want to revert to the original file?
            </p>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", justifyContent: "center" }}>
            <Button
              variant="secondary"
              onClick={handleCloseshowConformPopforRevertdata}
              style={{
                marginRight: "40px",
                backgroundColor: "#f7f7f7",
                color: "#444",
                boxShadow: "1px 1px 4px rgba(0,0,0,0.4)",
                border: "none",
                borderRadius: "3px",
                fontSize: "13px",
                textTransform: "uppercase",
                padding: "8px 16px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={Revertsplit}
              style={{
                backgroundColor: "#1C9F6F",
                color: "#fff",
                boxShadow: "1px 1px 4px rgba(0,0,0,0.4)",
                border: "none",
                borderRadius: "3px",
                fontSize: "13px",
                textTransform: "uppercase",
                padding: "8px 16px",
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                className="fa fa-check ch-up"
                style={{ color: "#19A73F" }}
              />{" "}
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          style={{ marginTop: "8em" }}
          show={notesPopup}
          onHide={() => {
            setNotesPopup(false);
          }}
          top
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>Add Notes</h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setNotesPopup(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body>
            <div class="row" style={{ padding: "0px 17px" }}>
              <div class="col-md-2 pd-0">
                <h5 class="h5-sz">Notes:</h5>
              </div>
              <div class="col-md-10 pd-0">
                <div class="ninput-group">
                  <textarea
                    class="form-control custom-control f_cn"
                    rows="3"
                    style={{ resize: "none" }}
                    value={newNote || ""}
                    onChange={(e) => {
                      setNewNote(e.target.value);
                    }}
                  />

                  <div class="bt_add">
                    {newNote != null ? (
                      <FontAwesomeIcon
                        className="bit"
                        icon={faPaperPlane}
                        onClick={uploadNewNotes}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div class="row">
                {Note.length > 0 && (
                  <div class="history">
                    <div class="col-md-2 pd-0">
                      <h5 class="h5-sz" style={{ marginLeft: "-10px" }}>
                        History:
                      </h5>
                    </div>
                    <div class="col-md-10 pd-0 scrol-pop ">
                      <div class="hiscont ">
                        {Note.map((x) => {
                          return (
                            <ul class="timeline">
                              <li class="timeline-inverted">
                                <div
                                  class="timeline-badge"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {x.date.split(" ")[0] +
                                    " " +
                                    x.date.split(" ")[1]}
                                  <br />
                                  {x.date.split(" ")[2]}
                                </div>
                                <div class="timeline-panel">
                                  <div class="row">
                                    <div class="col-md-6 post_u">
                                      <p class="by_post">{x.postedby}</p>
                                    </div>
                                    <div class="col-md-6 g_date">
                                      <p class="glyph_u">
                                        <AccessTimeIcon
                                          className="glyphicon glyphicon-time"
                                          style={{
                                            fontSize: "12.5px",
                                            position: "relative",
                                            top: "-2px",
                                          }}
                                        />{" "}
                                        {/* <FontAwesomeIcon
                                        icon={faClock}
                                        className="glyphicon glyphicon-time"
                                      />{" "} */}
                                        {x.date.split(" ")[3] +
                                          " " +
                                          x.date.split(" ")[4]}{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="flex-container flex-around">
                                    <div class="box arrow-left timeline-heading">
                                      <textarea
                                        style={{ resize: " none" }}
                                        readonly
                                      >
                                        {x.notes}
                                      </textarea>
                                      <p class="com"></p>
                                    </div>
                                    <p
                                      style={{
                                        position: "absolute",
                                        margin: "35px 0px 0px 85%",
                                        color: "red",
                                      }}
                                    >
                                      <Tooltip title="Delete">
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            DeleteNotes(x);
                                          }}
                                          icon={faTrashCan}
                                          className="fa fa-trash-o"
                                          style={{
                                            fontSize: "12px",
                                            cursor: "pointer",
                                          }}
                                          matTooltip="Delete"
                                        />
                                      </Tooltip>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showdeletealert}
          onHide={handleCloseshowdeletealert}
          top
          size="md"
          style={{ marginTop: "6em" }}
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                Delete Invoice
              </h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                handleCloseshowdeletealert(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body>
            <p
              style={{
                fontSize: "15px",
                color: "#666",
                margin: "10px 0px 25px 0px",
              }}
            >
              Please select reason for deletion
            </p>
            <select
              className="form-control mb-4"
              value={DropdownChangeForDeleteReason}
              onChange={handleDropdownChangeForDeleteReason}
              style={{
                border: "1px solid",
                marginBottom: "15px",
                width: "90%",
                margin: "auto",
              }}
            >
              <option value="" disabled selected>
                Select The Reason
              </option>
              <option value="Duplicate Invoice">Duplicate Invoice</option>
              <option value="Not An Invoice">Not An Invoice</option>
              <option value="Test Account">Test Account</option>
              <option value="De-Activated Account">De-Activated Account</option>
              <option value="Recurring Expense">Recurring Expense</option>
            </select>
          </Modal.Body>
          <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
            <Bbutton
              variant="contained"
              onClick={handleCloseshowdeletealert}
              style={{
                borderRadius: "3px",
                backgroundColor: "#9b9b9f",
                fontSize: "13px",
                padding: "6px 16px",
                marginRight: "10px",
                lineHeight: "1.846",
              }}
            >
              Cancel
            </Bbutton>
            <Bbutton
              variant="contained"
              color="error"
              onClick={Delete}
              style={{
                borderRadius: "3px",
                backgroundColor: "#19A73F",
                fontSize: "13px",
                padding: "6px 16px",
                lineHeight: "1.846",
              }}
            >
              Delete
            </Bbutton>
          </Modal.Footer>
        </Modal>
        {/* delete popup */}
        <Modal
          show={showdelete}
          onHide={() => setShowdelete(false)}
          style={{ marginTop: "5em" }}
        >
          <Modal.Header
            style={{
              backgroundColor: "rgb(25, 167, 63)",
              height: "45px",
              borderRadius: "3px",
            }}
          >
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
                <FontAwesomeIcon icon={faTriangleExclamation} /> Warning
              </h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setShowdelete(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            Please confirm if you want to delete this line item
          </Modal.Body>
          <Modal.Footer
            style={{ justifyContent: "center", borderStyle: "none" }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowdelete(false)}
              style={{
                background: "#9b9b9f",
                border: "none",
                color: "#fff",
                borderRadius: "3px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              style={{
                background: "#19A73F",
                border: "none",
                borderRadius: "3px",
              }}
              onClick={() => DeleteRowItem(recorddelete, recorddeleteindex)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Invoice No */}
        <Modal
          show={showInvoiceNo}
          onHide={() => setShowInvoiceNo(false)}
          centered
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>Alert</h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setShowInvoiceNo(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            Please check Invoice Number, before proceeding to Next step
          </Modal.Body>
          <Modal.Footer style={{ borderStyle: "none" }}>
            <Button
              variant="secondary"
              style={{ background: "#4caf50", border: "none" }}
              onClick={InvoiceNoChange}
            >
              Ok
            </Button>
            <Button
              variant="danger"
              style={{ border: "none" }}
              onClick={InvoiceNumberNotChange}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* InVoice Date */}
        <Modal
          show={showInvoiceDate}
          onHide={() => setShowInvoiceDate(false)}
          centered
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>Alert</h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setShowInvoiceDate(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            Please check Invoice date, before proceeding to Next step
          </Modal.Body>
          <Modal.Footer style={{ borderStyle: "none" }}>
            <Button
              variant="secondary"
              style={{ background: "#4caf50", border: "none" }}
              onClick={InvoiceDateChange}
            >
              Ok
            </Button>
            <Button
              variant="danger"
              style={{ border: "none" }}
              onClick={InvoiceDateNotChange}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* InVoice Date & Invoice No */}
        <Modal
          show={showInvDateNo}
          onHide={() => setShowInvDateNo(false)}
          centered
        >
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>Alert</h5>
            </Modal.Title>
            <span
              className="close"
              onClick={() => {
                setShowInvDateNo(false);
              }}
            >
              <span class="closeicon">&times;</span>
            </span>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            Please check Invoice Number & Invoice Date, before proceeding to
            Next step
          </Modal.Body>
          <Modal.Footer style={{ borderStyle: "none" }}>
            <Button
              variant="secondary"
              style={{ background: "#4caf50", border: "none" }}
              onClick={InvoiceDateNoChange}
            >
              Ok
            </Button>
            <Button
              variant="danger"
              style={{ border: "none" }}
              onClick={InvoiceDateNoNotChange}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={mergeshow}
          onHide={handlemergeClose}
          backdrop="static"
          keyboard={false}
          top
          dialogClassName="modal-lg modal-xl custom-modal"
        >
          <Modal.Body style={{ paddingBottom: "0px" }}>
            <Spin tip="Loading Please wait..." spinning={loaderForMergePdf}>
              <div
                style={{
                  display: "flex",
                  height: "500px",
                  border: "1px solid #DDD",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    flex: "0 0 25%",
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "1px solid #DDD",
                  }}
                >
                  {" "}
                  {/* Column 1 */}
                  <div
                    style={{
                      overflowY: "auto",
                      padding: "8px 20px",
                      flexGrow: 1,
                    }}
                  >
                    {images?.map((image, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: "20px", position: "relative" }}
                        draggable
                        onDragStart={handleDragStart(index)}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop(index)}
                      >
                        <p
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          Page {index + 1}
                        </p>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <IoMdCloseCircle
                            onClick={() => removeImage(index)}
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              color: "#666666",
                              cursor: "pointer",
                              borderRadius: "50%",
                            }}
                          />
                          <img
                            src={image}
                            alt={`thumbnail-${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              border:
                                selectedImageIndex === index
                                  ? "2px solid #19A73F"
                                  : "1px solid #DDD",
                              cursor: "pointer",
                            }}
                            onClick={() => setSelectedImageIndex(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ padding: "10px" }}>
                    {" "}
                    {/* Fixed Button */}
                    <button
                      className="mergebutton"
                      onClick={() => setmergeShow(false)}
                    >
                      <FaPlus /> Add Invoice
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    flex: "0 0 75%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {/* Column 2 */}
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      overflow: "hidden",
                    }}
                  >
                    {selectedImageIndex !== null && (
                      <img
                        src={images[selectedImageIndex]}
                        alt="Selected"
                        style={{
                          maxHeight: "90%",
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}

                    {allImagesClosed && (
                      <div style={{ padding: "8px 20px", textAlign: "center" }}>
                        <p
                          style={{
                            color: "#000",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Add invoice to merge PDF
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}> 
    <button className="mergepagination-button">1</button>
    <button className="mergepagination-button">2</button>
    <button className="mergepagination-button">3</button>
</div>*/}
                </div>
              </div>
            </Spin>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <button className="mergeclose" onClick={handlemergeClose}>
              Close
            </button>
            <button
              className="mergeaspdf"
              disabled={allImagesClosed}
              onClick={mergePfd}
            >
              Merge as PDF
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showMergeAlert} onHide={handleCloseMergeAlert} centered>
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "22px", marginBottom: "0" }}>Warning</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are selected a different restaurant.please select a same
            restaurant and try again
          </Modal.Body>
          <Modal.Footer>
            <Bbutton variant="contained" onClick={handleCloseMergeAlert}>
              OK
            </Bbutton>
          </Modal.Footer>
        </Modal>

        <Modal show={mappingInfo} onHide={() => { changeCategory(changedLineItemList) }} centered>
          <Modal.Header style={{ height: "45px", borderRadius: "3px" }}>
            <Modal.Title>
              <h5 style={{ fontSize: "20px", marginBottom: "0" }}>Mapping Info</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div aria-hidden="true">
            <p>The description was mapped to <b>{statistics}</b> by the Account Manager.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div aria-hidden="true" style={{ display: 'flex', gap: '10px' }} >
              <Button
                type="primary"
                style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b25c',
                }}
                onClick={() => { changeCategory(changedLineItemList) }}
              >
                Close
              </Button>
              {/* <Button
                type="primary"
                style={{
                  backgroundColor: '#19A73F',
                  color: '#fff',
                  borderRadius: '3px',
                  border: 'none'
                }}
                onClick={() => { }}
              >
                Okay
              </Button> */}
            </div>
          </Modal.Footer>
        </Modal>


      </div>
    </>
  );
};
export default Invoice;
